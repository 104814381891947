import { MutationTree } from 'vuex';
import { MessageState } from './typings';
import {
  SET_INBOXES,
  SET_MESSAGE,
  SET_UNREAD_COUNT,
  ADD_MESSAGE,
} from './mutation-types';

const mutations: MutationTree<MessageState> = {
  [SET_INBOXES]: (state: MessageState, inboxes) => {
    state.Inboxes = inboxes;
  },
  [SET_MESSAGE]: (state: MessageState, message) => {
    state.Message = message;
  },
  [SET_UNREAD_COUNT]: (state: MessageState, unReadCount) => {
    state.UnReadCount = unReadCount;
  },
  [ADD_MESSAGE]: (state: MessageState, message) => {
    if (state.Message.Items.some((x) => x.Id === message.Id) == false) {
      state.Message.Items.push({
        Id: message.Id,
        Content: message.Content,
        SentDate: message.SentDate,
        FromAffiliate: message.FromAffiliate,
      });
      if (message.Attachments != null) {
        message.Attachments.forEach((item) => {
          state.Message.Attachments.push({
            Id: item.Rowguid,
            FileName: item.FileName,
            Content: item.Content,
          });
        });
      }
    }
  },
};
export default mutations;
