import Vue from 'vue';
import toCurrencyFormat from '@/filters/toCurrencyFormat';
import toNumericFormat from '@/filters/toNumericFormat';
import toISOTimeFormat from '@/filters/toISOTimeFormat';
import toISODateFormat from '@/filters/toISODateFormat';

/*
How to add filters
Vue.filter("filter-name", function(value) {
    //execute method
});
*/

Vue.filter('toCurrency', (value) => toCurrencyFormat(value));
Vue.filter('toNumeric', (value) => toNumericFormat(value));
Vue.filter('toISOTime', (value) => toISOTimeFormat(value));
Vue.filter('toISODate', (value) => toISODateFormat(value));
