<template>
  <div class="text-center">
    <VBtn
      class="flex-grow-1"
      color="primary"
      v-for="(item, index) in downLoadButton"
      @click.stop="buttonClick(index)"
      :key="index"
    >
      <span class="betfont icon-download"></span>
      <span class="text-sm tracking-tight pl-2">{{ item.text }}</span>
    </VBtn>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { VBtn } from 'vuetify/lib';
export default {
  name: 'DialogQueueDownload',
  components: {
    VBtn,
  },
  props: {
    downLoadButton: {
      type: Array,
    },
  },
  methods: {
    buttonClick(index) {
      const setting = this.downLoadButton[index];
      if (setting?.action) {
        setting.action();
      }
      //this.close();
    },
    ...mapActions({ close: 'DialogQueue/close' }),
  },
};
</script>
<style lang="scss" scoped>
.text-link {
  color: $blue-link;
}
</style>
