import { supportLanguages } from '@/constants/language.constant';
import store from '@/store/store';
import { importLayout, importPage } from '@/utils/dynamic-import';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

/*
  add "meta: { requireAuth: true }" to route config for login check
*/
export const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'root',
    redirect: (to) => {
      return `/${store.state.gv.lan}`;
    },
  },
  {
    path: `/:lang(${supportLanguages.join('|')})`,
    component: () => importLayout('HeaderFooterLayout.vue'),
    children: [
      {
        path: '',
        name: 'default',
        meta: { requireAuth: true, requireIpBlocked: true },
        component: () => importPage('HomePage.vue'),
      },
      {
        path: 'successpage',
        name: 'SuccessPage',
        component: () => importPage('SuccessPage.vue'),
      },
      //myAccount
      {
        path: 'myAccount',
        name: `myAccount`,
        meta: { requireAuth: true },
        component: () => importPage(`menu/ProfilePage.vue`),
      },
      {
        path: 'register',
        name: `register`,
        component: () => importPage(`RegistrationPage.vue`),
      },
      {
        path: 'creatives',
        name: `Creatives`,
        meta: { requireAuth: true },
        component: () => importPage(`menu/CreativesPage.vue`),
        children: [
          {
            path: '',
            meta: { requireAuth: true },
            redirect: (to) => {
              return 'links';
            },
          },
          {
            path: 'links/:code?',
            name: 'creative-link-and-banners',
            alias: 'banners',
            meta: { requireAuth: true },
            components: {
              links: () =>
                import(
                  /* webpackChunkName: "[request]" */
                  '@/components/creatives/LinksList.vue'
                ),
              banners: () =>
                import(
                  /* webpackChunkName: "[request]" */
                  '@/components/creatives/BannersList.vue'
                ),
            },
          },
        ],
      },
      {
        path: 'myAccount/profile-verification',
        name: `ProfileVerification`,
        meta: { requireAuth: true },
        component: () => importPage(`ProfileVerification.vue`),
      },
      {
        path: 'myAccount/profile-verification/:ischeckWD',
        name: 'ProfileVerificationShowThirdParty',
        meta: { requireAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "[request]" */
            '@/pages/ProfileVerification.vue'
          ),
        props: true,
      },
      {
        path: 'myAccount/profile-verification/verify-success',
        name: `ProfileVerificationVerifySuccess`,
        meta: { requireAuth: true },
        component: () => importPage(`ProfileVerification.vue`),
      },
      {
        path: 'myAccount/profile-verification/verify-error',
        name: `ProfileVerificationVerifyError`,
        meta: { requireAuth: true },
        component: () => importPage(`ProfileVerification.vue`),
      },
      {
        path: 'reports',
        name: `Reports`,
        meta: { requireAuth: true },
        component: () => importPage(`menu/ReportsPage.vue`),
        children: [
          {
            path: '',
            meta: { requireAuth: true },
            redirect: (to) => {
              return 'summary-report';
            },
          },
          {
            path: 'summary-report',
            name: 'summary-report',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/SummaryReport.vue'
              ),
          },
          {
            path: 'collateral-performance/:yearMonth?',
            name: 'collateral-performance',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/CollateralPerformance.vue'
              ),
          },
          {
            path: 'sub-affiliates/:yearMonth?/:affCode?/:settlementType?',
            name: 'sub-affiliates',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/SubAffiliates.vue'
              ),
          },
          {
            path: 'gpr',
            meta: { requireAuth: true },
            redirect: (to) => {
              return 'gross-product-revenue';
            },
          },
          {
            path:
              'gross-product-revenue/:yearMonth/:settlementType/live-casino',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/GrossProductRevenueLiveCasinoSummary.vue'
              ),
          },
          {
            path:
              'gross-product-revenue/:yearMonth/:settlementType/:productName',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/GrossProductRevenueMemberBetListing.vue'
              ),
          },
          {
            path: 'gross-product-revenue',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/GrossProductRevenue.vue'
              ),
            children: [
              {
                path: '',
                redirect: (to) => {
                  return 'summary';
                },
              },
              {
                path: ':yearMonth',
                name: 'gross-product-revenue-product-summary',
                alias: 'summary',
                meta: { requireAuth: true },
                components: {
                  summary: () =>
                    import(
                      /* webpackChunkName: "[request]" */
                      '@/components/reports/GrossProductRevenueMonthlySummary.vue'
                    ),
                  yearMonth: () =>
                    import(
                      /* webpackChunkName: "[request]" */
                      '@/components/reports/GrossProductRevenueProductSummary.vue'
                    ),
                },
              },
            ],
          },
          {
            path: 'referrals',
            meta: { requireAuth: true },
            children: [],
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/Referrals.vue'
              ),
          },
          {          
            path: 'statement/:yearMonth/:memberCode/:currencyCode',
            name: 'memberstatement',            
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/MemberStatement.vue'
              ),
          },
          {
            path: 'monthly-commissions',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/MonthlyCommissions.vue'
              ),
          },
          {
            path: 'commission-payout',
            meta: { requireAuth: true },
            component: () =>
              import(
                /* webpackChunkName: "[request]" */
                '@/components/reports/CommissionPayout.vue'
              ),
          },
        ],
      },
      {
        path: 'payments',
        name: `Payments`,
        meta: { requireAuth: true },
        component: () => importPage(`menu/PaymentsPage.vue`),
      },
      {
        path: 'announcement',
        name: `Announcement`,
        component: () => importPage(`menu/AnnouncementPage.vue`),
        /*
        redirect: (to) => {
          return 'messages/inbox';
        },
        */
      },
      {
        path: 'messages/inboxes/:id',
        name: 'messages-inbox-detail',
        meta: { requireAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "[request]" */
            '@/components/messages/InboxDetail.vue'
          ),
      },
      {
        path: 'messages',
        name: 'Messages',
        meta: { requireAuth: true },
        component: () => importPage(`menu/MessagesPage.vue`),
        children: [
          {
            path: '',
            meta: { requireAuth: true },
            redirect: (to) => {
              return 'inboxes';
            },
          },
          {
            path: 'inboxes',
            name: 'messages-inbox-and-announcement',
            alias: 'announcements',
            meta: { requireAuth: true },
            components: {
              inboxes: () =>
                import(
                  /* webpackChunkName: "[request]" */
                  '@/components/messages/InboxList.vue'
                ),
              announcements: () =>
                import(
                  /* webpackChunkName: "[request]" */
                  '@/components/messages/AnnouncementList.vue'
                ),
            },
          },
        ],
      },
      {
        path: 'success',
        name: 'Success',
        component: () => importPage('SuccessPage.vue'),
      },
      {
        path: 'read-message',
        name: 'Read Message',
        component: () => importPage('ReadMessagePage.vue'),
      },
    ],
  },
  {
    path: `/:lang(${supportLanguages.join('|')})`,
    component: () => importLayout('NoMenuHeaderLayout.vue'),
    children: [
      {
        path: 'forbidden',
        name: 'forbidden',
        component: () => importPage('ForbiddenPage.vue'),
      },
    ],
  },
  {
    path: `/:lang(${supportLanguages.join('|')})/full-table-view`,
    meta: { requireAuth: true },
    component: () => importLayout('FooterOnlyLayout.vue'),
    children: [
      {
        path: 'sub-affiliates/:yearMonth?',
        name: 'full-table-view-sub-affiliates',
        component: () =>
          import(
            /* webpackChunkName: "[request]" */
            '@/components/reports/SubAffiliatesFullTableView.vue'
          ),
      },
      {
        path: 'collateral-performances/:yearMonth?',
        name: 'full-table-view-collateral-performances',
        component: () =>
          import(
            /* webpackChunkName: "[request]" */
            '@/components/reports/CollateralPerformanceFullTableView.vue'
          ),
      },
    ],
  },
  {
    path: `/:lang(${supportLanguages.join('|')})`,
    component: () => importLayout('FooterOnlyLayout.vue'),
    children: [
      {
        path: 'full-view',
        name: 'fullView',
        component: () => importPage('FullTableView.vue'),
      },
    ],
  },
  {
    path: `/:lang(${supportLanguages.join('|')})`,
    component: () => importLayout('FooterOnlyLayout.vue'),
    children: [
      {
        path: 'referrals-full-view',
        name: 'referralsFullView',
        component: () => importPage('ReferralsFullTableView.vue'),
      },
    ],
  },
  {
    path: `/:lang(${supportLanguages.join('|')})`,
    meta: { requireIpBlocked: true },
    component: () => importLayout('HeaderFooterLayout.vue'),
    children: [
      {
        //static page edit by WebTeam
        path: ':viewName',
        name: 'StaticContent',
        component: () => importPage('menu/StaticContentPage.vue'),
      },
    ],
  },
  {
    path: '*',
    redirect: (to) => {
      return '/';
    },
  },
];

function anonymousOnly(to: Route, from: Route, next: NavigationGuardNext) {
  if (store.getters.isLogin === false) {
    next();
  } else {
    next(false);
  }
}

function authorizedOnly(to: Route, from: Route, next: NavigationGuardNext) {
  if (store.getters.isLogin === true) {
    next();
  } else {
    next({ path: `/${store.state.gv.lan}/user/login` });
  }
}
