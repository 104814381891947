import { CommonState } from './../Common/typings';
import { MutationTree } from 'vuex';
import {
  WebState,
  SecurityQuestion,
  LanguageModel,
  StaticContentResult,
  CountryItem,
} from './typings';
import {
  GET_SECURITY_QUESTIONS,
  SET_LANGUAGE,
  SET_STATIC_CONTENT,
  SET_HTML_RESOURCE,
  SET_SHOW_LOGIN_DIALOG,
  SET_SHOW_SIGNUP_DIALOG,
  SET_SHOW_FORGET_ACCOUNT_PASSWORD_DIALOG,
  SET_COUNTRY,
  SET_HIDE_ON_SCROLL_DOWN_IN_MOBILE_ENABLE,
} from './mutation-types';

const mutations: MutationTree<WebState> = {
  [GET_SECURITY_QUESTIONS]: (
    state: WebState,
    securityQuestion: SecurityQuestion[]
  ) => {
    state.SecurityQuestions = securityQuestion;
  },
  [SET_LANGUAGE]: (state: WebState, languages: LanguageModel[]) => {
    state.Languages = languages;
  },
  [SET_COUNTRY]: (state: WebState, countries: CountryItem[]) => {
    state.Countries = countries;
  },
  [SET_STATIC_CONTENT]: (
    state: WebState,
    staticContent: StaticContentResult
  ) => {
    state.StaticContent = staticContent;
  },
  [SET_HTML_RESOURCE]: (state: WebState, htmlResource: string) => {
    state.HtmlResource = htmlResource;
  },
  [SET_SHOW_LOGIN_DIALOG]: (state: WebState, value: boolean) => {
    resetDialog(state, value);
    state.showLoginDialog = value;
  },
  [SET_SHOW_SIGNUP_DIALOG]: (state: WebState, value: boolean) => {
    resetDialog(state, value);
    state.showSignUpDialog = value;
  },
  [SET_SHOW_FORGET_ACCOUNT_PASSWORD_DIALOG]: (
    state: WebState,
    value: boolean
  ) => {
    resetDialog(state, value);
    state.showForgetAccountPasswordDialog = value;
  },
  [SET_HIDE_ON_SCROLL_DOWN_IN_MOBILE_ENABLE]: (state: WebState) => {
    state.TheHeader.HideOnScrollDownInMobile = true;
  },
};
export default mutations;

function resetDialog(state: WebState, value: boolean) {
  if (value === true) {
    if (state.showSignUpDialog === true) state.showSignUpDialog = false;
    if (state.showLoginDialog === true) state.showLoginDialog = false;
    if (state.showForgetAccountPasswordDialog === true)
      state.showForgetAccountPasswordDialog = false;
  }
}
