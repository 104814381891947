import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { setDefaultHeader } from '@/utils/axios-instance';
import axiosInstance from '@/utils/axios-instance';
Vue.use(VueI18n);

const loadedLanguages: string[] = [];

export const i18n: VueI18n = new VueI18n();

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  setDefaultHeader('Accept-Language', lang);
  var _match = window.gv.lans.find((x) => x.value === lang);
  if (_match != null) {
    if (document.documentElement.lang === _match.code) {
    } else {
      document.documentElement.lang = _match.code;
    }
  }
  return lang;
}

export function loadLanguageAsync(lang: string) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  const verison = Math.round(new Date().getTime() / 1000);
  const i18nFileUrl = `/resource/i18n/${lang}.json?v=` + verison;
  return axiosInstance.get(i18nFileUrl).then((response) => {
    loadedLanguages.push(lang);
    i18n.setLocaleMessage(lang, response.data);
    return setI18nLanguage(lang);
  });
}

export default i18n;
