import { Module } from 'vuex';
import { CommonState, Language } from './typings';
import { RootState } from '@/store/typings';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state: CommonState = {
  Languages: [],
  Countrys: [],
  ChatTools: [
    {
      Code: '',
      LanguageKey: '',
      IsEnabled: true,
    },
  ],
  DialCodes: [],
  Currencys: {
    Currencies: [],
    Default: '',
  },
  AllCountries: {
    CountryList: [
      {
        CountryCode: '',
        CountryDisplayName: '',
      },
    ],
  },
  AllCurrencies: {
    CurrencyList: [
      {
        CurrencyCode: '',
      },
    ],
  },
  Last3Months: [],
  CurrentLangCode: '',
  CurrentCountryCode: '',
};
const Account: Module<CommonState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
export default Account;
