import storage from '@/utils/storage'
import { logout } from '@/store/modules/auth/api'
let lastTime = new Date().getTime()
let currentTime = new Date().getTime()
let timeOut =  60 * 60 * 1000  

window.onload = function () {
  window.document.onmousedown = function () {
    storage.setItem("lastTime", new Date().getTime())
  }
};

function checkTimeout() {
  currentTime = new Date().getTime()
  lastTime = storage.getItem("lastTime");
  if (currentTime - lastTime > timeOut) { 
    storage.clear()  
    logout();    
  }
}

export default function () {
  window.setInterval(checkTimeout, 30000);
}