export default {
    setItem(key, value) {
    value = JSON.stringify(value);
    window.localStorage.setItem(key, value)
    },
    getItem(key) {  
    let value;
    try {
         value = JSON.parse(window.localStorage.getItem(key) || '{}')
    } catch {}
    return value
    },
    removeItem(key) {
    window.localStorage.removeItem(key)
    },
    clear() {
    window.localStorage.clear()
    },
}