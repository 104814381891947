import { MutationTree } from 'vuex';
import { MarketingState } from './typings';
import * as _mutation from './mutation-types';

const mutations: MutationTree<MarketingState> = {
  [_mutation.SET_COLLATERAL_LIST]: (state: MarketingState, collateralList) => {
    state.CollateralList = collateralList;
  },

  [_mutation.SET_LINK_LIST]: (state: MarketingState, links) => {
    state.Links = links;
  },

  [_mutation.SET_COLLATERAL_CLASSIFICATION_LIST]: (
    state: MarketingState,
    collateralClassificationList
  ) => {
    state.CollateralClassificationList = collateralClassificationList;
  },

  [_mutation.SET_INVALID_LINK_CODE]: (
    state: MarketingState,
    invalidLinkCode
  ) => {
    state.InvalidLinkCode = invalidLinkCode;
  },

  [_mutation.SET_SCRIPT]: (state: MarketingState, marketingScript) => {
    state.MarketingScript = marketingScript;
  },
  [_mutation.SET_BANNER_FILTER]: (state: MarketingState, bannerFilter) => {
    state.BannerFilter = bannerFilter;
  },
  [_mutation.SET_BANNER_LISTING]: (state: MarketingState, bannerListing) => {
    state.BannerListing = bannerListing;
  },
  [_mutation.SET_LINKLISTING]: (state: MarketingState, linklisting) => {
    state.LinkListing.Home = linklisting.Home;
    state.LinkListing.SignUp = linklisting.SignUp;
    state.LinkListing.Sub = linklisting.Sub;
    state.LinkListing.Players = linklisting.Players;
    state.LinkListing.Deprecations = linklisting.Deprecations;
  },
  [_mutation.SET_LINKDETAIL]: (state: MarketingState, linkdetail) => {
    state.LinkDetail.Type = linkdetail.Type;
    state.LinkDetail.LinkTo = linkdetail.LinkTo;
    state.LinkDetail.Code = linkdetail.Code;
    state.LinkDetail.Links = linkdetail.Links;
  },
  [_mutation.SET_PLAYERLINKCREATED]: (
    state: MarketingState,
    playerLinkCreatedItem
  ) => {
    state.PlayerLinkCreatedItem.Id = playerLinkCreatedItem.Id;
    state.PlayerLinkCreatedItem.Code = playerLinkCreatedItem.Code;
  },
  [_mutation.SET_PRODUCTLISTING]: (state: MarketingState, products) => {
    state.Products = products;
  },
};
export default mutations;
