export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_GPRSUMMARY_LIST = 'SET_GPRSUMMARY_LIST';
export const SET_GPR_PRODUCT_FILTER_SELECTED_ITEMS =
  'SET_GPR_PRODUCT_FILTER_SELECTED_ITEMS';
export const SET_GPRPRODUCTSUMMARY_LIST = 'SET_GPRPRODUCTSUMMARY_LIST';
export const SET_GPRPRODUCTDETAIL_LIST = 'SET_GPRPRODUCTDETAIL_LIST';
export const SET_GPRMEMBERDETAIL_LIST = 'SET_GPRMEMBERDETAIL_LIST';
export const SET_GPRFILTERDETAIL_LIST = 'SET_GPRFILTERDETAIL_LIST';
export const SET_COMMISSION_PAYOUT = 'SET_COMMISSION_PAYOUT';
export const SET_COMMISSION_PAYOUT_DETAIL = 'SET_COMMISSION_PAYOUT_DETAIL';
export const SET_COMMISSION_PAYOUT_CANCEL = 'SET_COMMISSION_PAYOUT_CANCEL';
export const SET_MONTHLY_COMMISSION_SUMMARY = 'SET_MONTHLY_COMMISSION_SUMMARY';
export const SET_MONTHLY_COMMISSION_DETAIL = 'SET_MONTHLY_COMMISSION_DETAIL';
export const SET_MONTHLY_PROMOBONUS = 'SET_MONTHLY_PROMOBONUS';
export const SET_REFERRAL_LIST = 'SET_REFERRAL_LIST';
export const SET_TRANSACTION_HISTORY = 'SET_TRANSACTION_HISTORY';
export const SET_AWARD_BONUS_SUMMARY_LIST = 'SET_AWARD_BONUS_SUMMARY_LIST';
export const SET_AWARD_BONUS_LIST = 'SET_AWARD_BONUS_SUMMARY_LIST';
export const SET_AWARD_BONUS_BALANCE = 'SET_AWARD_BONUS_BALANCE';
export const SET_SUBMIT_AWARD_BONUS_RESULT = 'SET_SUBMIT_AWARD_BONUS_RESULT';
export const SET_AFFILIATE_PROMOTING_LIST = 'SET_AFFILIATE_PROMOTING_LIST';
export const SET_SUBAFFILIATES_LIST = 'SET_SUBAFFILIATES_LIST';
export const SET_SUBAFFILIATES_TOTAL_COMMMISSION =
  'SET_SUBAFFILIATES_TOTAL_COMMMISSION';
export const SET_ENABLECONTACT = 'SET_ENABLECONTACT';
export const SET_COLLATERALPERFORMANCE_LIST = 'SET_COLLATERALPERFORMANCE_LIST';
export const SET_COLLATERALPERFORMANCE_DETAIL =
  'SET_COLLATERALPERFORMANCE_DETAIL';
export const SET_COLLATERALPERFORMANCE_DOMAIN_COUNTER =
  'SET_COLLATERALPERFORMANCE_DOMAIN_COUNTER';
export const SET_MEMBER_SUMMARY =
  'SET_MEMBER_SUMMARY';