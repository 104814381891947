import { httpGet, httpPost } from '@/utils/axios-instance';

export function login(username: string, password: string, blackbox: string) {
  const data = {
    Username: username,
    Password: password,
    iovationBlackbox: blackbox,
  };
  return httpPost(`/Api/Web/login`, data, false);
}

export function IsSessionExpired() {
  return httpGet(`/Api/Web/IsSessionExpired`, {}, false);
}

export function logout() {
  return httpPost(`/Api/Web/logout`);
}

export function RedirectTo188MemberSite() {
  return httpGet(`/Api/Web/RedirectTo188MemberSite`);
}

export function IsIpBlocked() {
  return httpGet(`/Api/Web/IsIpBlocked`, {}, false);
}
