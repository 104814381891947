import { AttachmentItem, Inboxes, MessageDetail } from './typings';
import axiosInstance from '@/utils/axios-instance';
import { AxiosResponse } from 'axios';
import { ResponseModel } from '../Web/typings';

export function getInboxes(
  page: number,
  pageSize: number
): Promise<AxiosResponse<Inboxes>> {
  const _url = `/Api/Messages/Inboxes?page=${page}&pageSize=${pageSize}`;
  return axiosInstance.get(_url, {}, false);
}

export function getMessage(id: string): Promise<AxiosResponse<MessageDetail>> {
  const _url = `/Api/Messages/GetMessage/${id}`;
  return axiosInstance.get(_url, {}, false);
}

export function getUnReadCount(): Promise<AxiosResponse<number>> {
  const _url = `/Api/Messages/UnReadCount`;
  return axiosInstance.get(_url, {}, false);
}

export function draft(
  id: string,
  subject: string,
  content: string,
  attachments: AttachmentItem[]
): Promise<AxiosResponse<string>> {
  const _url = '/Api/Messages/Draft';
  const _data = {
    Id: id,
    Subject: subject,
    Content: content,
    Attachments: attachments,
  };
  return axiosInstance.post(_url, _data, false);
}

export function composeMessage(
  id: string,
  subject: string,
  content: string,
  attachments: AttachmentItem[]
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Messages/ComposeMessage';
  const _data = {
    Id: id,
    Subject: subject,
    Content: content,
    Attachments: attachments,
  };
  return axiosInstance.post(_url, _data, false);
}

export function sentMessage(
  id: string,
  content: string,
  attachments: AttachmentItem[]
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Messages/SentMessage';
  const _data = { Id: id, Content: content, Attachments: attachments };
  return axiosInstance.post(_url, _data, false);
}

export function deleteMessage(
  items: string[]
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Messages/DeleteMessage';
  return axiosInstance.post(_url, items, false);
}

export function markAsRead(
  items: string[]
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Messages/MarkAsRead';
  return axiosInstance.put(_url, items, false);
}

export function markAsUnRead(
  items: string[]
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Messages/MarkAsUnRead';
  return axiosInstance.put(_url, items, false);
}
