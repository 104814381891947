import axiosInstance from '@/utils/axios-instance';
import { AxiosResponse } from 'axios';
import {
  JumioLaunchModel,
  UpLoadlistModel,
  AddDocfileModel,
  OccupationModel,
  DeclarationModel,
  FirstTimeWDModel,
  CheckProfileStatusModel,
  WDStatusRequestModel,
} from './typings';
import { ResponseModel } from '../Web/typings';

export function getJumioLaunchUrl(JumioLaunchModel: JumioLaunchModel) {
  const data = {
    Id: JumioLaunchModel.Id,
    Language: JumioLaunchModel.language,
    needSelfie: JumioLaunchModel.needSelfie,
    successUrl: JumioLaunchModel.successUrl,
    errorUrl: JumioLaunchModel.errorUrl,
  };
  return axiosInstance.post('/Api/Kyc/GetJumioLaunchUrl', data, false);
}

export function AddDocFileUpload(
  value: UpLoadlistModel
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/AddDocFileUpload';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function RemoveDocFileUpload(
  value: UpLoadlistModel
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/RemoveDocFileUpload';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function InserDocFile(
  value: AddDocfileModel
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/InserDocFile';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function getProfileStatus(
  value: CheckProfileStatusModel
): Promise<AxiosResponse<ResponseModel>> {
  const _param = value;
  return axiosInstance.post('/Api/Kyc/GetProfileStatus', _param, false);
}

export function getVerificationStatus() {
  return axiosInstance.get('/Api/Kyc/GetVerificationStatus', {}, false);
}

export function UpdateOccupation(
  value: OccupationModel
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/UpdateOccupation';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function UpdateDeclaration(
  value: DeclarationModel
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/UpdateDeclaration';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function getRemarks() {
  return axiosInstance.get('/Api/Kyc/GetPortalRemarks', {}, false);
}

export function UpdateProofOfIdentity(): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/UpdateProofOfIdentity';
  return axiosInstance.post(_url, {}, false);
}

export function UpdateFirstTimeWD(
  value: FirstTimeWDModel
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Kyc/UpdateFirstTimeWD';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function getWDStatus(value: WDStatusRequestModel) {
  const _param = value;
  return axiosInstance.post('/Api/Kyc/GetPortalWDStatus', _param, false);
}
