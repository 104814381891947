import Vue from 'vue';
import { loadLanguageAsync } from '../i18n/i18n';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { supportLanguages } from '@/constants/language.constant';
import { routes } from '@/router/router.config';
import store from '@/store/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  // store.commit('LoadingMask/SHOW_LOADING_MASK');
  // store.commit('SET_FROM_ROUTE', from);
  const targetLang = to.params.lang;
  const lang = supportLanguages.includes(targetLang)
    ? targetLang
    : supportLanguages[0];

  //login status check: redirect to root route when not logged in.
  if (to.meta.requireAuth === true) {
    store.dispatch('Auth/checkSession').then(() => {
      const _isLogin: boolean = store.getters['Auth/isLogin'];
      if (_isLogin === false) {
        next({ name: 'root' });
      }
    });
  }

  if (to.meta.requireIpBlocked === true) {
    store.dispatch('Auth/checkIpBlocked').then(() => {
      const _isIpBlocked: boolean = store.getters['Auth/isIpBlocked'];
      if (_isIpBlocked === false) {
        next({ name: 'root' });
      }
    });
  }

  loadLanguageAsync(lang)
    .then(() => {
      next();
    })
    .catch((error) => {
      // console.log('error:', error);
      next();
    });
});

router.afterEach((to: Route, from: Route) => {
  // store.dispatch('LoadingMask/hideLoadingMask');
  // store.commit('Menu/CLOSE_BURGER_MENU');
});

export default router;
