export const SET_COMMON_LANGUAGES = 'SET_COMMON_LANGUAGES';
export const SET_COMMON_COUNTRY = 'SET_COMMON_COUNTRY';
export const SET_COMMON_CHATTOOLS = 'SET_COMMON_CHATTOOLS';
export const SET_COMMON_DIALCODES = 'SET_COMMON_DIALCODES';
export const SET_CURRENT_LANG = 'SET_CURRENT_LANG';
export const SET_COMMON_CURRENCY = 'SET_COMMON_CURRENCY';
export const SET_COMMON_ALLCOUNTRIES = 'SET_COMMON_ALLCOUNTRIES';
export const SET_COMMON_ALLCURRENCIES = 'SET_COMMON_ALLCURRENCIES';
export const SET_COMMON_LAST3MONTHS = 'SET_COMMON_LAST3MONTHS';
export const GET_CURRENT_LANG = 'GET_CURRENT_LANG';
export const GET_COUNTRY_IP = 'GET_COUNTRY_IP';
