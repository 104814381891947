import axiosInstance from '@/utils/axios-instance';
import { AxiosResponse } from 'axios';
import { ResponseModel } from './../Web/typings';
import * as _marketing from './typings';

export function getBannerFilter(): Promise<
  AxiosResponse<_marketing.BannerLanguagesItem[]>
> {
  const _url = '/Api/Marketing/GetBannerFilter';
  return axiosInstance.get(_url, {}, false);
}

export function getBannerListing(
  query: _marketing.BannerListingQuery
): Promise<AxiosResponse<_marketing.BannerListingItem[]>> {
  const _url = '/Api/Marketing/GetBannerListing';
  return axiosInstance.post(_url, query, false);
}

export function getInvalidLinkCode(): Promise<AxiosResponse<string[]>> {
  return axiosInstance.get('/Api/Marketing/InvalidLinkCode', {}, false);
}

export function getCollateralList(
  content: _marketing.FilterModel,
  page: number
): Promise<AxiosResponse<_marketing.CollateralListModel>> {
  const _url = `/Api/Marketing/CollateralList?page=${page}`;
  return axiosInstance.post(_url, content, false);
}

export function getLink(): Promise<
  AxiosResponse<_marketing.MarketingLinkModel[]>
> {
  return axiosInstance.get('/Api/Marketing/Link', {}, false);
}

export function getCollateralClassificationList(
  id: string
): Promise<AxiosResponse<_marketing.CollateralClassificationListModel>> {
  const _url = `/Api/Marketing/CollateralClassificationList/${id}`;
  return axiosInstance.get(_url, {}, false);
}

export function addLink(
  content: _marketing.AddLinkModel
): Promise<AxiosResponse<ResponseModel>> {
  return axiosInstance.post('/Api/Marketing/AddLink', content, false);
}

export function getScript(
  id: string
): Promise<AxiosResponse<_marketing.MarketingScriptModel>> {
  const _url = `/Api/Marketing/Script/${id}`;
  return axiosInstance.get(_url, {}, false);
}

export function addScript(
  marketingScriptModel: _marketing.MarketingScriptModel
): Promise<AxiosResponse<_marketing.MarketingScriptModel>> {
  return axiosInstance.post(
    '/Api/Marketing/Script',
    marketingScriptModel,
    false
  );
}

export function getLinkListing(): Promise<
  AxiosResponse<_marketing.LinkListing>
> {
  const _url = `/Api/Marketing/GetLinkListing`;
  return axiosInstance.get(_url, {}, false);
}

export function getLinkDetail(
  code: string
): Promise<AxiosResponse<_marketing.LinkDetail>> {
  const _url = `/Api/Marketing/GetLinkDetail?code=${code}`;
  return axiosInstance.get(_url, {}, false);
}

export function getLinkDeleteAllowed(code: string) {
  return axiosInstance.get(
    `/Api/Marketing/GetLinkDeleteAllowed?code=${code}`,
    {},
    false
  );
}

export function getPlayerLinkCreated(): Promise<
  AxiosResponse<_marketing.GetPlayerLinkCreated>
> {
  const _url = '/Api/Marketing/GetPlayerLinkCreated';
  return axiosInstance.get(_url, {}, false);
}

export function playerLinkCreated(
  playerLinkCreated: _marketing.PlayerLinkCreated
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Marketing/playerLinkCreated';
  const data = {
    Code: playerLinkCreated.Code,
    LinkTo: playerLinkCreated.LinkTo,
  };
  return axiosInstance.post(_url, data, false);
}

export function deleteplayerLink(code: string) {
  return axiosInstance.get(`/Api/Marketing/DeleteLink?code=${code}`, {}, false);
}
