import { ActionTree } from 'vuex';
import { CommonState } from './typings';
import { RootState } from '@/store/typings';
import {
  getLanguage,
  getChatTools,
  getDialCode,
  setCurrentLang,
  getCurrencys,
  getAllCountries,
  getAllCurrencies,
  getLast3Months,
  getCurrentLang,
  getCountryByIP,
} from './api';
import {
  SET_COMMON_LANGUAGES,
  SET_COMMON_CHATTOOLS,
  SET_COMMON_DIALCODES,
  SET_COMMON_CURRENCY,
  SET_COMMON_ALLCOUNTRIES,
  SET_COMMON_ALLCURRENCIES,
  SET_COMMON_LAST3MONTHS,
  GET_CURRENT_LANG,
  GET_COUNTRY_IP,
} from './mutation-types';
const actions: ActionTree<CommonState, RootState> = {
  async getLanguage({ commit }, id): Promise<any> {
    const result = await getLanguage(id);
    const setting = result.data;
    commit(SET_COMMON_LANGUAGES, setting);
  },
  async getDialCode({ commit }): Promise<any> {
    const result = await getDialCode();
    const setting = result.data;
    commit(SET_COMMON_DIALCODES, setting);
  },
  async setCurrentLang({}, langCode) {
    const result = await setCurrentLang(langCode);
  },
  async getCurrentLang({ commit }) {
    const result = await getCurrentLang();
    const setting = result.data;
    commit(GET_CURRENT_LANG, setting);
  },
  async getCurrencys({ commit }, countryCode): Promise<any> {
    const result = await getCurrencys(countryCode);
    const setting = result.data;
    commit(SET_COMMON_CURRENCY, setting);
  },

  async fetchChatTools({ commit }, langCode): Promise<any> {
    const result = await getChatTools(langCode);
    const setting = result.data;
    commit(SET_COMMON_CHATTOOLS, setting);
  },
  async fetchAllCountries({ commit }) {
    const result = await getAllCountries();
    const AllCountries = result.data;
    commit(SET_COMMON_ALLCOUNTRIES, AllCountries);
  },
  async fetchAllCurrencies({ commit }) {
    const result = await getAllCurrencies();
    const AllCurrencies = result.data;
    commit(SET_COMMON_ALLCURRENCIES, AllCurrencies);
  },
  async fetchLast3Months({ commit }) {
    const result = await getLast3Months();
    const Last3Months = result.data;
    commit(SET_COMMON_LAST3MONTHS, Last3Months);
  },
  async getCountryByIP({ commit }) {
    const result = await getCountryByIP();
    const setting = result.data;
    commit(GET_COUNTRY_IP, setting);
  },
};

export default actions;
