import { MutationTree } from 'vuex';
import { KycState } from './typings';
import {
  GET_JUMIOLAUNCH_MODEL,
  GET_PROFILESTATUS_MODEL,
  GET_VERIFICATIONSTATUS_MODEL,
  GET_OCCUPATION_MODEL,
  GET_DECLARATION_MODEL,
  GET_REMARKS_MODEL,
  GET_WD_STATUS_MODEL,
} from './mutation-types';

const mutations: MutationTree<KycState> = {
  [GET_JUMIOLAUNCH_MODEL]: (state: KycState, JumioLaunchUrlResultModel) => {
    state.JumioLaunchUrlResultModel = JumioLaunchUrlResultModel;
  },
  [GET_PROFILESTATUS_MODEL]: (state: KycState, ProfileStatusModel) => {
    state.ProfileStatusModel.photoIdStatus = ProfileStatusModel.PhotoIdStatus;
    state.ProfileStatusModel.occupationStatus =
      ProfileStatusModel.OccupationStatus;
    state.ProfileStatusModel.threePartyStatus =
      ProfileStatusModel.ThreePartyStatus;
    state.ProfileStatusModel.photoIdDescr = ProfileStatusModel.PhotoIdDescr;
    state.ProfileStatusModel.occupationDescr =
      ProfileStatusModel.OccupationDescr;
    state.ProfileStatusModel.threePartyDescr =
      ProfileStatusModel.ThreePartyDescr;
  },
  [GET_VERIFICATIONSTATUS_MODEL]: (
    state: KycState,
    VerificationStatusModel
  ) => {
    state.VerificationStatusModel.verificationStatus =
      VerificationStatusModel.VerificationStatus;
  },
  [GET_OCCUPATION_MODEL]: (state: KycState, OccupationModel) => {
    state.OccupationModel.isFullTime = OccupationModel.isFullTime;
    state.OccupationModel.isPartTime = OccupationModel.isPartTime;
    state.OccupationModel.occupationComment = OccupationModel.OccupationComment;
  },
  [GET_REMARKS_MODEL]: (state: KycState, PortalRemarksModel) => {
    state.PortalRemarksModel.RemarksList = PortalRemarksModel.RemarksList;
  },
  [GET_WD_STATUS_MODEL]: (state: KycState, WDStatusModel) => {
    state.WDStatusModel.isfirstTimeWD = WDStatusModel.isfirstTimeWD;
    state.WDStatusModel.isChangeWD = WDStatusModel.isChangeWD;
  },
};
export default mutations;
