import { ActionTree } from 'vuex';
import {
  KycState,
  UpLoadlistModel,
  AddDocfileModel,
  OccupationModel,
  DeclarationModel,
  FirstTimeWDModel,
  CheckProfileStatusModel,
  WDStatusRequestModel,
} from './typings';
import { RootState } from '@/store/typings';
import {
  getJumioLaunchUrl,
  InserDocFile,
  getProfileStatus,
  getVerificationStatus,
  UpdateOccupation,
  UpdateDeclaration,
  getRemarks,
  UpdateProofOfIdentity,
  getWDStatus,
  UpdateFirstTimeWD,
} from './api';
import {
  GET_JUMIOLAUNCH_MODEL,
  GET_PROFILESTATUS_MODEL,
  GET_VERIFICATIONSTATUS_MODEL,
  GET_REMARKS_MODEL,
  GET_WD_STATUS_MODEL,
} from './mutation-types';
import { ResponseModel } from '../Web/typings';
const actions: ActionTree<KycState, RootState> = {
  async fetchJumioLaunchUrl({ commit }, JumioLaunchModel) {
    const result = await getJumioLaunchUrl(JumioLaunchModel);
    commit(GET_JUMIOLAUNCH_MODEL, result);
  },

  async AddDocFile({}, model: AddDocfileModel): Promise<ResponseModel> {
    var _result = await InserDocFile(model);
    var _response = _result.data;
    return _response;
  },

  async fetchProfileStatus({ commit }, CheckProfileStatusModel) {
    var result = await getProfileStatus(CheckProfileStatusModel);
    const payload = result.data;
    commit(GET_PROFILESTATUS_MODEL, payload);
  },

  async fetchVerificationStatus({ commit }) {
    const result = await getVerificationStatus();
    commit(GET_VERIFICATIONSTATUS_MODEL, result.data);
  },

  async UpdateOccupation({}, model: OccupationModel): Promise<ResponseModel> {
    var _result = await UpdateOccupation(model);
    var _response = _result.data;
    return _response;
  },

  async UpdateDeclaration({}, model: DeclarationModel): Promise<ResponseModel> {
    var _result = await UpdateDeclaration(model);
    var _response = _result.data;
    return _response;
  },

  async fetchRemarks({ commit }) {
    const result = await getRemarks();
    commit(GET_REMARKS_MODEL, result.data);
  },

  async UpdateProofOfIdentity({}): Promise<ResponseModel> {
    var _result = await UpdateProofOfIdentity();
    var _response = _result.data;
    return _response;
  },

  async UpdateFirstTimeWD({}, model: FirstTimeWDModel): Promise<ResponseModel> {
    var _result = await UpdateFirstTimeWD(model);
    var _response = _result.data;
    return _response;
  },

  async fetchWDStatus({ commit }, model: WDStatusRequestModel) {
    const result = await getWDStatus(model);
    commit(GET_WD_STATUS_MODEL, result.data);
  },
};

export default actions;
