import { ActionTree } from "vuex";
import { AnnouncementState } from "./typings";
import { RootState } from "@/store/typings";
import { getAnnouncementList, getMarqueeLogin } from "./api";
import { SET_ANNOUNCEMENT_LIST, SET_MARQUEE_LOGIN } from "./mutation-types";
const actions: ActionTree<AnnouncementState, RootState> = {
  async fetchAnnouncementList({ commit }) {
    const result = await getAnnouncementList();
    const profile = result.data;
    commit(SET_ANNOUNCEMENT_LIST, profile);
  },
  async fetchMarqueeLogin({ commit }, type) {
    const result = await getMarqueeLogin(type);
    const message = result.data;
    commit(SET_MARQUEE_LOGIN, message);
  },
};

export default actions;
