import { Module } from 'vuex';
import { MarketingState } from './typings';
import { RootState } from '@/store/typings';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state: MarketingState = {
  BannerQuery: {
    SelectedLanguageId: '',
    SelectedClassificationId: [],
    SelectAllClassifications: false,
  },
  BannerFilter: [
    {
      Id: '',
      Code: '',
      Name: '',
      IsPreferred: false,
      IsDefault: false,
      Classifications: [
        {
          Id: '',
          Name: '',
        },
      ],
    },
  ],
  BannerListing: [
    {
      Id: '',
      Classification: '',
      Url: '',
      LangDescr: '',
      TypeDescr: '',
      SizeCode: '',
      Name: '',
      EffectiveDateText: '',
    },
  ],
  CollateralList: {
    Status: 0,
    Message: '',
    Params: null,
    CollateralList: [
      {
        Id: '',
        Classification: '',
        PreviewUrl: '',
        LangDescr: '',
        TypeDescr: '',
        SizeCode: '',
        Name: '',
        EffectiveDate: '',
      },
    ],
    Page: {
      Size: 0,
      Total: 0,
      Count: 0,
      IndexStart: 0,
      BarSize: 0,
      Index: 0,
    },
  },
  Links: [
    {
      Id: '',
      Type: '',
      Code: '',
      LinkUrl: '',
      QRUrl: '',
    },
  ],
  CollateralClassificationList: {
    Status: 0,
    Message: '',
    Params: null,
    CollateralClassificationList: [
      {
        Id: '',
        LangId: '',
        LangDescr: '',
        Descr: '',
      },
    ],
  },
  MarketingScript: {
    Status: 0,
    Message: '',
    Params: null,
    Id: '',
    Name: '',
    Script: '',
  },
  Filter: {
    LangId: '',
    ClassId: '',
    Page: 0,
  },
  InvalidLinkCode: [],
  Page: 0,
  LinkListing: {
    Home: {
      Id: '',
      Code: '',
      Type: '',
    },
    SignUp: {
      Id: '',
      Code: '',
      Type: '',
    },
    Sub: {
      Id: '',
      Code: '',
      Type: '',
    },
    Players: [
      {
        Id: '',
        Code: '',
        Type: '',
        LinkTo: '',
      },
    ],
    Deprecations: [
      {
        Id: '',
        Code: '',
        LinkUrl: '',
        QRUrl: '',
        Type: '',
      },
    ],
  },
  LinkDetail: {
    Type: '',
    LinkTo: '',
    Code: '',
    Links: [
      {
        LinkName: '',
        QRCode: '',
        Sort: 0,
      },
    ],
  },
  LinkFilter: {
    code: '',
  },
  PlayerLinkCreatedItem: {
    Id: 0,
    Code: '',
  },
  PlayerLinkCreated: {
    Code: '',
    LinkTo: '',
  },
  LinkDelete: {
    Code: '',
    isOriginalItem: 0,
  },
  Products: [
    {
      Id: 0,
      Code: '',
      Key: '',
    },
  ],
};

const Marketing: Module<MarketingState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default Marketing;
