export const SET_COLLATERAL_LIST = 'SET_COLLATERAL_LIST';
export const SET_LINK_LIST = 'SET_LINK_LIST';
export const SET_INVALID_LINK_CODE = 'SET_INVALID_LINK_CODE';
export const SET_COLLATERAL_CLASSIFICATION_LIST =
  'SET_COLLATERAL_CLASSIFICATION_LIST';
export const SET_SCRIPT = 'SET_SCRIPT';
export const SET_BANNER_FILTER = 'SET_BANNER_FILTER';
export const SET_BANNER_LISTING = 'SET_BANNER_LISTING';
export const SET_LINKLISTING = 'SET_LINKLISTING';
export const SET_LINKDETAIL = 'SET_LINKDETAIL';
export const SET_PLAYERLINKCREATED = 'SET_PLAYERLINKCREATED';
export const SET_PRODUCTLISTING = 'SET_PRODUCTLISTING';
