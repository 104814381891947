export const SET_ACCOUNT_PROFILE = 'SET_ACCOUNT_PROFILE';
export const SET_ACCOUNT_SECURITY = 'SET_ACCOUNT_SECURITY';
export const SET_ACCOUNT_COMMISSION_BALANCE = 'SET_ACCOUNT_COMMISSION_BALANCE';
export const SET_ACCOUNT_WEBSITE = 'SET_ACCOUNT_WEBSITE';
export const SET_ACCOUNT_CHANGE_PASSWORD = 'SET_ACCOUNT_CHANGE_PASSWORD';
export const SET_ACCOUNT_IS_FIRST_LOGIN = 'SET_ACCOUNT_IS_FIRST_LOGIN';
export const SET_ACCOUNT_AFFILIATE_ID = 'SET_ACCOUNT_AFFILIATE_ID';
export const SET_COMMRCV_LIST = 'SET_COMMRCV_LIST';
export const SET_JUMIOLAUNCH_MODEL = 'SET_JUMIOLAUNCH_MODEL';
export const SET_ACCOUNT_TRANSACTION = 'SET_ACCOUNT_TRANSACTION';
