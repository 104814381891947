<template>
  <div id="app">
    <VApp>
      <DialogQueue />
      <router-view v-if="isReloadAlive" />
    </VApp>
  </div>
</template>
<script>
/*eslint no-undef: "off"*/
if (process.env.NODE_ENV === 'production') {
  __webpack_public_path__ = window.gv.cdnurl + __webpack_public_path__;
} else {
  __webpack_public_path__ = 'http://localhost:8080' + __webpack_public_path__;
}
import Vue from 'vue';
import DialogQueue from '@/store/modules/dialogQueue/components/DialogQueue.vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import VueGtm from '@gtm-support/vue2-gtm';
import router from './router/router';
const isProduction = process.env.NODE_ENV === 'production';

export default {
  components: { DialogQueue },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data: () => ({
    loggedIn: false,
    isReloadAlive: true,
    timmer: 0,
    isExcute: false,
  }),
  created() {
    Vue.use(VueGtm, {
      id: window.gv.googletagid,
      defer: true,
      enabled: true,
      debug: !isProduction,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    });
  },
  methods: {
    ...mapActions('Auth', ['logout']),
    reload() {
      this.isReloadAlive = false;
      this.$nextTick(function() {
        this.isReloadAlive = true;
      });
    },
  },
};
</script>
//
<style lang="scss">
$cdn-path: './assets/';
@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap';
@import './assets/scss/02_molecules/typography';
@import './assets/scss/04_templates/common-layout';
</style>
