/* 
Example
  '2023-06-16T23:57:28.937' => '23:57'
  'today' => 'today'
*/
export default function toTimeFormat(isoDateString: string): string {
  if (isNaN(Date.parse(isoDateString)) === true) return isoDateString;
  const _current: Date = new Date(isoDateString);
  const _options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };
  const _timeString = new Intl.DateTimeFormat('en-us', _options).format(
    _current
  );
  return _timeString;
  /*
  if (isoDateString.length >= 16) {
    var _timeString = isoDateString.substring(11, 16);
    return _timeString;
  }
  return isoDateString;
  */
}
