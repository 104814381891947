import { ActionTree } from 'vuex';
import {
  AccountState,
  ResetAccountPassword,
  SubmitTransaction,
} from './typings';
import { RootState } from '@/store/typings';
import {
  getAffiliateProfile,
  updateProfile,
  getAffiliateCommissionBalance,
  getAccountWebSite,
  updateAccountWebSite,
  changePassword,
  getisFirstLogin,
  getAffiliateCode,
  getAffiliateCommRcvList,
  getSecurityQuestion as getSecurityQuestionByUserNameOrEmail,
  sumbitResetAccountPassword,
  getTransaction,
  submitTransaction,
} from './api';
import {
  SET_ACCOUNT_PROFILE,
  SET_ACCOUNT_COMMISSION_BALANCE,
  SET_ACCOUNT_WEBSITE,
  SET_ACCOUNT_CHANGE_PASSWORD,
  SET_ACCOUNT_IS_FIRST_LOGIN,
  SET_ACCOUNT_AFFILIATE_ID,
  SET_COMMRCV_LIST,
  SET_ACCOUNT_TRANSACTION,
} from './mutation-types';
import { ResponseModel } from '../Web/typings';

const actions: ActionTree<AccountState, RootState> = {
  async fetchAffiliateProfile({ commit }) {
    const result = await getAffiliateProfile();
    const profile = result.data;
    commit(SET_ACCOUNT_PROFILE, profile);
  },
  async updateProfile({ commit }, AccountProfile) {
    const result = await updateProfile(AccountProfile);
    if (result.data.Status == 0) {
      commit(SET_ACCOUNT_PROFILE, AccountProfile);
    }
    return result;
  },
  async fetchAffiliateCommissionBalance({ commit }) {
    const result = await getAffiliateCommissionBalance();
    const balance = result.data;
    commit(SET_ACCOUNT_COMMISSION_BALANCE, balance);
  },

  async fetchAccountWebSite({ commit }) {
    const result = await getAccountWebSite();
    const profile = result.data;
    commit(SET_ACCOUNT_WEBSITE, profile);
  },

  async updateAccountWebSite({ commit }, AccountWebSite) {
    const result = await updateAccountWebSite(AccountWebSite);
    if (result.data.Status == 0) {
      commit(SET_ACCOUNT_WEBSITE, AccountWebSite);
    }
  },
  async changePassword({ commit }, changePDModel) {
    var result = await changePassword(changePDModel);
    const payload = result.data;
    commit(SET_ACCOUNT_CHANGE_PASSWORD, payload);
  },
  async fetchisFirstLogin({ commit }) {
    var result = await getisFirstLogin();
    const payload = result.data;
    commit(SET_ACCOUNT_IS_FIRST_LOGIN, payload);
  },
  async fetchAffiliateCode({ commit }, ownaffiliateId) {
    var result = await getAffiliateCode(ownaffiliateId);
    const payload = result.data;
    commit(SET_ACCOUNT_AFFILIATE_ID, payload);
  },

  async fetchAffiliateCommRcvList({ commit }) {
    var result = await getAffiliateCommRcvList();
    const payload = result.data;
    commit(SET_COMMRCV_LIST, payload);
  },

  async getSecurityQuestionByUserNameOrEmail(
    { commit },
    userNameOrEmail: string
  ): Promise<ResponseModel> {
    var _result = await getSecurityQuestionByUserNameOrEmail(userNameOrEmail);
    var _response = _result.data;
    return _response;
  },

  async sumbitResetAccountPassword(
    { commit },
    model: ResetAccountPassword
  ): Promise<ResponseModel> {
    var _result = await sumbitResetAccountPassword(model);
    var _response = _result.data;
    return _response;
  },

  async fetchTransaction({ commit }) {
    var _result = await getTransaction();
    var _response = _result.data;
    commit(SET_ACCOUNT_TRANSACTION, _response);
  },
  async submitTransaction(
    { commit },
    model: SubmitTransaction
  ): Promise<boolean> {
    var _result = await submitTransaction(model);
    if (_result.Status === 204) return true;
    return false;
  },
};

export default actions;
