import axiosInstance from '@/utils/axios-instance';

export function getLanguage(id) {
  const params = { id };
  const payload = { params };
  return axiosInstance.get('/Api/Web/Language', payload, false);
}

export function getCountry(langCode) {
  const params = { langCode };
  const payload = { params };
  return axiosInstance.get(
    '/Api/Web/GetLocalizationCountryList',
    payload,
    false
  );
}

export function getChatTools(langCode: string) {
  const params = { langCode };
  const payload = { params };
  return axiosInstance.get('/Api/Web/ChatTools', payload, false);
}

export function getDialCode() {
  return axiosInstance.get('/Api/Web/NewDialCode', {}, false);
}

export function setCurrentLang(langCode) {
  const data = {
    langCode: langCode,
  };

  return axiosInstance.post('/Api/Web/SetCurrentLang', data, false);
}

export function getCurrentLang() {
  return axiosInstance.get('/Api/Web/GetCurrentLang', {}, false);
}

export function getCurrencys(CountryCode) {
  const params = { CountryCode };
  const payload = { params };
  return axiosInstance.get('/Api/Web/Currency', payload, false);
}

export function getAllCountries() {
  return axiosInstance.post('/Api/Web/GetAllCountries', {}, false);
}
export function getAllCurrencies() {
  return axiosInstance.post('/Api/Web/GetAllCurrencies', {}, false);
}
export function getLast3Months() {
  return axiosInstance.post('/Api/Web/GetLast3Months', {}, false);
}

export function getCountryByIP() {
  return axiosInstance.get('/Api/Web/GetCountryByIP', {}, false);
}
