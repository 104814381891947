import { state } from './../../store';
import { MutationTree } from 'vuex';
import {
  ReportState,
  GPRProductSummaryResponse,
  GPRProductFilterItem,
  GPRSummaryResponse,
} from './typings';
import {
  SET_SUMMARY,
  SET_GPRSUMMARY_LIST,
  SET_GPRPRODUCTSUMMARY_LIST,
  SET_GPR_PRODUCT_FILTER_SELECTED_ITEMS,
  SET_GPRPRODUCTDETAIL_LIST,
  SET_GPRMEMBERDETAIL_LIST,
  SET_GPRFILTERDETAIL_LIST,
  SET_COMMISSION_PAYOUT,
  SET_COMMISSION_PAYOUT_DETAIL,
  SET_MONTHLY_COMMISSION_SUMMARY,
  SET_MONTHLY_COMMISSION_DETAIL,
  SET_MONTHLY_PROMOBONUS,
  SET_REFERRAL_LIST,
  SET_TRANSACTION_HISTORY,
  SET_AWARD_BONUS_SUMMARY_LIST,
  SET_AWARD_BONUS_LIST,
  SET_AWARD_BONUS_BALANCE,
  SET_SUBMIT_AWARD_BONUS_RESULT,
  SET_AFFILIATE_PROMOTING_LIST,
  SET_SUBAFFILIATES_LIST,
  SET_SUBAFFILIATES_TOTAL_COMMMISSION,
  SET_ENABLECONTACT,
  SET_COLLATERALPERFORMANCE_LIST,
  SET_COLLATERALPERFORMANCE_DETAIL,
  SET_COLLATERALPERFORMANCE_DOMAIN_COUNTER,
  SET_MEMBER_SUMMARY,
} from './mutation-types';

const mutations: MutationTree<ReportState> = {
  [SET_SUMMARY]: (state: ReportState, summary) => {
    state.Summary.code = summary.Code;
    (state.Summary.message = summary.Message),
      (state.Summary.data = summary.Data);
  },
  [SET_GPRSUMMARY_LIST]: (state: ReportState, GPRSummary) => {
    state.GPRSummary.Code = GPRSummary.Code;
    state.GPRSummary.Message = GPRSummary.Message;
    state.GPRSummary.Data = GPRSummary.Data;
  },
  [SET_GPRPRODUCTSUMMARY_LIST]: (state: ReportState, GPRProductSummary) => {
    state.GPRProductSummary.Code = GPRProductSummary.Code;
    state.GPRProductSummary.Message = GPRProductSummary.Message;
    state.GPRProductSummary.Data = GPRProductSummary.Data;
  },
  [SET_GPR_PRODUCT_FILTER_SELECTED_ITEMS]: (
    state: ReportState,
    selectedItems: GPRProductFilterItem[]
  ) => {
    state.GPRProductFilterSelectedItems = selectedItems;
  },
  [SET_GPRPRODUCTDETAIL_LIST]: (state: ReportState, GPRProductDetail) => {
    state.GPRProductDetail.ProductDetailList =
      GPRProductDetail.ProductDetailList;
  },
  [SET_GPRMEMBERDETAIL_LIST]: (state: ReportState, GPRMemberDetail) => {
    state.GPRMemberDetail.memberDetailList = GPRMemberDetail.memberDetailList;
  },
  [SET_GPRFILTERDETAIL_LIST]: (state: ReportState, GPRFilterDetail) => {
    state.GPRFilterDetail.Code = GPRFilterDetail.Code;
    state.GPRFilterDetail.Message = GPRFilterDetail.Message;
    state.GPRFilterDetail.Data = GPRFilterDetail.Data;
  },
  [SET_COMMISSION_PAYOUT]: (state: ReportState, commissionPayout) => {
    state.CommissionPayout.code = commissionPayout.Code;
    (state.CommissionPayout.message = commissionPayout.Message),
      (state.CommissionPayout.data = commissionPayout.Data);
  },
  [SET_COMMISSION_PAYOUT_DETAIL]: (
    state: ReportState,
    commissionPayoutDetail
  ) => {
    state.CommissionPayoutDetail.code = commissionPayoutDetail.Code;
    (state.CommissionPayoutDetail.message = commissionPayoutDetail.Message),
      (state.CommissionPayoutDetail.data = commissionPayoutDetail.Data);
  },
  [SET_MONTHLY_COMMISSION_SUMMARY]: (
    state: ReportState,
    monthlyCommissionSummary
  ) => {
    state.MonthlyCommissionSummary.Currency = monthlyCommissionSummary.Currency;
    (state.MonthlyCommissionSummary.SettlementCycle =
      monthlyCommissionSummary.SettlementCycle),
      (state.MonthlyCommissionSummary.MonthlyCommissions =
        monthlyCommissionSummary.MonthlyCommissions);
  },
  [SET_MONTHLY_COMMISSION_DETAIL]: (
    state: ReportState,
    monthlyCommissionDetail
  ) => {
    state.MonthlyCommissionDetail.AdjustmentMonthFirst =
      monthlyCommissionDetail.AdjustmentMonthFirst;
    (state.MonthlyCommissionDetail.AdjustmentMonthSecond =
      monthlyCommissionDetail.AdjustmentMonthSecond),
      (state.MonthlyCommissionDetail.BonusMonthFirst =
        monthlyCommissionDetail.BonusMonthFirst),
      (state.MonthlyCommissionDetail.BonusMonthSecond =
        monthlyCommissionDetail.BonusMonthSecond),
      (state.MonthlyCommissionDetail.ActivePlayerCountFirst =
        monthlyCommissionDetail.ActivePlayerCountFirst),
      (state.MonthlyCommissionDetail.ActivePlayerCountSecond =
        monthlyCommissionDetail.ActivePlayerCountSecond),
      (state.MonthlyCommissionDetail.ChessGameActiveMemberMonthFirst =
        monthlyCommissionDetail.ChessGameActiveMemberMonthFirst),
      (state.MonthlyCommissionDetail.ChessGameActiveMemberMonthSecond =
        monthlyCommissionDetail.ChessGameActiveMemberMonthSecond),
      (state.MonthlyCommissionDetail.ChessGameCommissionMonthFirst =
        monthlyCommissionDetail.ChessGameCommissionMonthFirst),
      (state.MonthlyCommissionDetail.ChessGameCommissionMonthSecond =
        monthlyCommissionDetail.ChessGameCommissionMonthSecond),
      (state.MonthlyCommissionDetail.ChessGameRewardRateMonthFirst =
        monthlyCommissionDetail.ChessGameRewardRateMonthFirst),
      (state.MonthlyCommissionDetail.ChessGameRewardRateMonthSecond =
        monthlyCommissionDetail.ChessGameRewardRateMonthSecond),
      (state.MonthlyCommissionDetail.ChessGameStakeMonthFirst =
        monthlyCommissionDetail.ChessGameStakeMonthFirst),
      (state.MonthlyCommissionDetail.ChessGameStakeMonthSecond =
        monthlyCommissionDetail.ChessGameStakeMonthSecond),
      (state.MonthlyCommissionDetail.Currency =
        monthlyCommissionDetail.Currency),
      (state.MonthlyCommissionDetail.LastPaidCommissionDateLongMonthFirst =
        monthlyCommissionDetail.LastPaidCommissionDateLongMonthFirst),
      (state.MonthlyCommissionDetail.LastPaidCommissionDateLongMonthSecond =
        monthlyCommissionDetail.LastPaidCommissionDateLongMonthSecond),
      (state.MonthlyCommissionDetail.LastPaidCommissionDateMonthFirst =
        monthlyCommissionDetail.LastPaidCommissionDateMonthFirst),
      (state.MonthlyCommissionDetail.LastPaidCommissionDateMonthSecond =
        monthlyCommissionDetail.LastPaidCommissionDateMonthSecond),
      (state.MonthlyCommissionDetail.NegativeCarryForwardMonthFirst =
        monthlyCommissionDetail.NegativeCarryForwardMonthFirst),
      (state.MonthlyCommissionDetail.NegativeCarryForwardMonthSecond =
        monthlyCommissionDetail.NegativeCarryForwardMonthSecond),
      (state.MonthlyCommissionDetail.PaidCommissionMonthFirst =
        monthlyCommissionDetail.PaidCommissionMonthFirst),
      (state.MonthlyCommissionDetail.PaidCommissionMonthSecond =
        monthlyCommissionDetail.PaidCommissionMonthSecond),
      (state.MonthlyCommissionDetail.PaymentFeeMonthFirst =
        monthlyCommissionDetail.PaymentFeeMonthFirst),
      (state.MonthlyCommissionDetail.PaymentFeeMonthSecond =
        monthlyCommissionDetail.PaymentFeeMonthSecond),
      (state.MonthlyCommissionDetail.PlatformFeeMonthFirst =
        monthlyCommissionDetail.PlatformFeeMonthFirst),
      (state.MonthlyCommissionDetail.PlatformFeeMonthSecond =
        monthlyCommissionDetail.PlatformFeeMonthSecond),
      (state.MonthlyCommissionDetail.ProductCommissionMonthFirst =
        monthlyCommissionDetail.ProductCommissionMonthFirst),
      (state.MonthlyCommissionDetail.ProductCommissionMonthSecond =
        monthlyCommissionDetail.ProductCommissionMonthSecond),
      (state.MonthlyCommissionDetail.ProductGrossRevenueMonthFirst =
        monthlyCommissionDetail.ProductGrossRevenueMonthFirst),
      (state.MonthlyCommissionDetail.ProductGrossRevenueMonthSecond =
        monthlyCommissionDetail.ProductGrossRevenueMonthSecond),
      (state.MonthlyCommissionDetail.ProductNetRevenueMonthFirst =
        monthlyCommissionDetail.ProductNetRevenueMonthFirst),
      (state.MonthlyCommissionDetail.ProductNetRevenueMonthSecond =
        monthlyCommissionDetail.ProductNetRevenueMonthSecond),
      (state.MonthlyCommissionDetail.ProductRewardRateMonthFirst =
        monthlyCommissionDetail.ProductRewardRateMonthFirst),
      (state.MonthlyCommissionDetail.ProductRewardRateMonthSecond =
        monthlyCommissionDetail.ProductRewardRateMonthSecond),
      (state.MonthlyCommissionDetail.ProductTurnoverMonthFirst =
        monthlyCommissionDetail.ProductTurnoverMonthFirst),
      (state.MonthlyCommissionDetail.ProductTurnoverMonthSecond =
        monthlyCommissionDetail.ProductTurnoverMonthSecond),
      (state.MonthlyCommissionDetail.SettlementCycle =
        monthlyCommissionDetail.SettlementCycle),
      (state.MonthlyCommissionDetail.TotalActiveMemberMonthFirst =
        monthlyCommissionDetail.TotalActiveMemberMonthFirst),
      (state.MonthlyCommissionDetail.TotalActiveMemberMonthSecond =
        monthlyCommissionDetail.TotalActiveMemberMonthSecond),
      (state.MonthlyCommissionDetail.TotalCommissionBalanceMonthFirst =
        monthlyCommissionDetail.TotalCommissionBalanceMonthFirst),
      (state.MonthlyCommissionDetail.TotalCommissionBalanceMonthSecond =
        monthlyCommissionDetail.TotalCommissionBalanceMonthSecond),
      (state.MonthlyCommissionDetail.TotalMemberCountMonthFirst =
        monthlyCommissionDetail.TotalMemberCountMonthFirst),
      (state.MonthlyCommissionDetail.TotalMemberCountMonthSecond =
        monthlyCommissionDetail.TotalMemberCountMonthSecond),
      (state.MonthlyCommissionDetail.SequenceMonthFirst =
        monthlyCommissionDetail.SequenceMonthFirst),
      (state.MonthlyCommissionDetail.SequenceMonthSecond =
        monthlyCommissionDetail.SequenceMonthSecond),
      (state.MonthlyCommissionDetail.StartDateMonthFirst =
        monthlyCommissionDetail.StartDateMonthFirst),
      (state.MonthlyCommissionDetail.EndDateMonthFirst =
        monthlyCommissionDetail.EndDateMonthFirst),
      (state.MonthlyCommissionDetail.StartDateMonthSecond =
        monthlyCommissionDetail.StartDateMonthSecond),
      (state.MonthlyCommissionDetail.EndDateMonthSecond =
        monthlyCommissionDetail.EndDateMonthSecond);
    (state.MonthlyCommissionDetail.TotalCommissionMonthFirst =
      monthlyCommissionDetail.TotalCommissionMonthFirst),
      (state.MonthlyCommissionDetail.TotalCommissionMonthSecond =
        monthlyCommissionDetail.TotalCommissionMonthSecond);
  },
  [SET_MONTHLY_PROMOBONUS]: (state: ReportState, MonthlyPromoBonus) => {
    state.MonthlyPromoBonus.code = MonthlyPromoBonus.Code;
    (state.MonthlyPromoBonus.message = MonthlyPromoBonus.Message),
      (state.MonthlyPromoBonus.data = MonthlyPromoBonus.Data);
  },
  [SET_REFERRAL_LIST]: (state: ReportState, referrals) => {
    state.Referrals.code = referrals.Code;
    (state.Referrals.message = referrals.Message),
      (state.Referrals.data = referrals.Data);
  },
  [SET_TRANSACTION_HISTORY]: (state: ReportState, transactionHistory) => {
    state.TransactionHistory = transactionHistory;
  },
  [SET_AWARD_BONUS_SUMMARY_LIST]: (state: ReportState, summary) => {
    state.AwardBonusSummary = summary;
  },
  [SET_AWARD_BONUS_LIST]: (state: ReportState, summary) => {
    state.AwardBonusSummary = summary;
  },
  [SET_AWARD_BONUS_BALANCE]: (state: ReportState, balance) => {
    state.AwardBonusBalance = balance;
  },
  [SET_SUBMIT_AWARD_BONUS_RESULT]: (state: ReportState, result) => {
    state.AwardBonusSubmissionResult = result;
  },
  [SET_AFFILIATE_PROMOTING_LIST]: (state: ReportState, promoting) => {
    state.AffiliatePromoting.code = promoting.Code;
    (state.AffiliatePromoting.message = promoting.Message),
      (state.AffiliatePromoting.data = promoting.Data);
  },
  [SET_SUBAFFILIATES_LIST]: (state: ReportState, subAffiliates) => {
    state.SubAffiliates = subAffiliates;
  },
  [SET_SUBAFFILIATES_TOTAL_COMMMISSION]: (
    state: ReportState,
    totalCommissions
  ) => {
    state.SubAffiliateTotalCommissions = totalCommissions;
  },
  [SET_ENABLECONTACT]: (state: ReportState, enableContact) => {
    state.EnableContact.isEnableContact = enableContact;
  },
  [SET_COLLATERALPERFORMANCE_LIST]: (
    state: ReportState,
    collateralPerformances
  ) => {
    state.CollateralPerformances = collateralPerformances;
  },
  [SET_COLLATERALPERFORMANCE_DETAIL]: (state: ReportState, query) => {
    state.CollateralPerformanceDetail = {
      CreativeId: '--',
      MediaSource: '--',
      BannerName: '--',
      BannerSize: '--',
      CollateralId: '--',
      ImpressionCount: 0,
      ClickCount: 0,
      NewSignUp: 0,
      NewSignUpDeposit: 0,
      NewSignUpRate: 0,
      NewSignUpDepositRate: 0,
      InterestMemberCount: 0,
    };
    state.CollateralPerformanceDomainList = {
      CreativeId: '--',
      MediaSource: '--',
      BannerName: '--',
      BannerSize: '--',
      Link1: '--',
      Link2: '--',
      Link3: '--',
    };
    state.CollateralPerformanceDomainCounter = {
      CreativeId: '--',
      MediaSource: '--',
      BannerSize: '--',
      Link: '--',
      ImpressionCount: 0,
      ClickCount: 0,
      NewSignUp: 0,
      NewSignUpDeposit: 0,
      NewSignUpRate: 0,
      NewSignUpDepositRate: 0,
      InterestMemberCount: 0,
    };
    let _size = query.BannerSize;
    if (_size == null) {
      _size = '-';
    }
    var _match = state.CollateralPerformances.find(
      (x) =>
        x.CreativeId.toLowerCase() == query.CreativeId.toLowerCase() &&
        x.MediaSource == query.MediaSource &&
        x.BannerSize == _size
    );
    if (_match == null) return;
    if (_match.Link1 != null) {
      state.CollateralPerformanceDomainList = {
        CreativeId: _match.CreativeId,
        MediaSource: _match.MediaSource,
        BannerName: _match.BannerName,
        BannerSize: _match.BannerSize,
        Link1: _match.Link1.Link,
        Link2: _match.Link2 == null ? '-' : _match.Link2.Link,
        Link3: _match.Link3 == null ? '-' : _match.Link3.Link,
      };
    } else {
      state.CollateralPerformanceDetail = {
        CreativeId: _match.CreativeId,
        MediaSource: _match.MediaSource,
        BannerName: _match.BannerName,
        BannerSize: _match.BannerSize,
        CollateralId: _match.CollateralId,
        ImpressionCount: _match.ImpressionCount,
        ClickCount: _match.ClickCount,
        NewSignUp: _match.NewSignUp,
        NewSignUpDeposit: _match.NewSignUpDeposit,
        NewSignUpRate: _match.NewSignUpRate,
        NewSignUpDepositRate: _match.NewSignUpDepositRate,
        InterestMemberCount: _match.InterestMemberCount,
      };
    }
  },
  [SET_COLLATERALPERFORMANCE_DOMAIN_COUNTER]: (state: ReportState, query) => {
    state.CollateralPerformanceDomainCounter = {
      CreativeId: '--',
      MediaSource: '--',
      BannerSize: '--',
      Link: '--',
      ImpressionCount: 0,
      ClickCount: 0,
      NewSignUp: 0,
      NewSignUpDeposit: 0,
      NewSignUpRate: 0,
      NewSignUpDepositRate: 0,
      InterestMemberCount: 0,
    };
    let _size = query.BannerSize;
    if (_size == null) {
      _size = '-';
    }
    var _match = state.CollateralPerformances.find(
      (x) =>
        x.CreativeId.toLowerCase() == query.CreativeId.toLowerCase() &&
        x.MediaSource == query.MediaSource &&
        x.BannerSize == _size
    );
    if (_match == null) return;
    var _links = [_match.Link1];
    if (_match.Link2 != null) _links.push(_match.Link2);
    if (_match.Link3 != null) _links.push(_match.Link3);
    let _matchLink = _links.find((x) => x.Link === query.Domain);
    if (_matchLink == null) return;
    state.CollateralPerformanceDomainCounter = {
      CreativeId: _match.CreativeId,
      MediaSource: _match.MediaSource,
      BannerSize: _match.BannerSize,
      Link: _matchLink.Link,
      ImpressionCount: _matchLink.ImpressionCount,
      ClickCount: _matchLink.ClickCount,
      NewSignUp: _matchLink.NewSignUp,
      NewSignUpDeposit: _matchLink.NewSignUpDeposit,
      NewSignUpRate: 0,
      NewSignUpDepositRate: 0,
      InterestMemberCount: _matchLink.InterestMemberCount,
    };
  },
  [SET_MEMBER_SUMMARY]: (state: ReportState, membersummary) => {
    state.SummaryInfos = membersummary;    
  },
};
export default mutations;
