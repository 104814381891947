/*
  var _options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };
  var _result = new Intl.DateTimeFormat('en-us', _options).format(_current);
  _current = 2023-06-16T23:57:28.937, result => 6/16/2023, 23:57:28
*/

/* 
Example
  '2023-06-16T23:57:28.937' => '06/16/23'
  'today' => 'today'
*/
export default function toISODateFormat(isoDateString: string): string {
  if (isNaN(Date.parse(isoDateString)) === true) return isoDateString;
  var _current = new Date(isoDateString);
  var _monthAndDay = isoDateString.substring(5, 10).replace('-', '/');
  var _yearMonth = isoDateString.substring(2, 4);

  var _now = new Date();

  if (
    _now.getDate() == _current.getDate() &&
    _now.getMonth() == _current.getMonth() &&
    _now.getFullYear() == _current.getFullYear()
  ) {
    //return 'today';
  }

  return `${_monthAndDay}/${_yearMonth}`;
}
