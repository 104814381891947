import { Module } from "vuex";
import { AnnouncementState } from "./typings";
import { RootState } from "@/store/typings";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state: AnnouncementState = {
  Announcement: {
    AnnouncementList: [
      {
        Id: "",
        Ord: 0,
        Text: "",
        EffectiveDate: "",
        Language: "",
        Type: "",
      },
    ],
  },
  AnnouncementMessage: {
    Content: "",
  },
};

const Announcement: Module<AnnouncementState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
export default Announcement;
