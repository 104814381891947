export default function toCurrencyFormat(value : number) : string
{
    let _precision : number = 2;
    let _locales : string = 'en-US';
    let _options : Intl.NumberFormatOptions = { 
        maximumFractionDigits: _precision, 
        minimumFractionDigits: _precision
    };

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat 
    return new Intl.NumberFormat(_locales, _options).format(value);
}