import axiosInstance from "@/utils/axios-instance";

export function getAnnouncementList() {
  return axiosInstance.get("/Api/Announcement/AnnouncementList", {}, false);
}

export function getMarqueeLogin(type: number) {
  const params = { type };
  const payload = { params };
  return axiosInstance.get("/Api/Web/PublicMessage", payload, false);
}
