import { ResponseModel } from '@/store/modules/Web/typings';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/typings';
import {
  MarketingState,
  FilterModel,
  MarketingScriptModel,
  AddLinkModel,
  BannerListingQuery,
  PlayerLinkCreated,
  //LinkFilter,
  //LinkDelete,
} from './typings';
import {
  SET_BANNER_FILTER,
  SET_BANNER_LISTING,
  SET_COLLATERAL_LIST,
  SET_LINK_LIST,
  SET_COLLATERAL_CLASSIFICATION_LIST,
  SET_INVALID_LINK_CODE,
  SET_SCRIPT,
  SET_LINKLISTING,
  SET_LINKDETAIL,
  SET_PRODUCTLISTING as SET_PRODUCT_LISTING,
} from './mutation-types';
import {
  getBannerFilter,
  getBannerListing,
  getCollateralClassificationList,
  getCollateralList,
  getLink,
  getScript,
  addLink,
  addScript,
  getLinkListing,
  getLinkDetail,
  getLinkDeleteAllowed,
  getPlayerLinkCreated,
  playerLinkCreated,
  deleteplayerLink,
} from './api';

const actions: ActionTree<MarketingState, RootState> = {
  async fetchBannerFilter({ commit }) {
    const _result = await getBannerFilter();
    commit(SET_BANNER_FILTER, _result.data);
  },
  async fetchBannerListing({ commit }, query: BannerListingQuery) {
    const _result = await getBannerListing(query);
    commit(SET_BANNER_LISTING, _result.data);
  },

  async fetchCollateralList({ commit }, content: FilterModel) {
    const _result = await getCollateralList(content, content.Page);
    commit(SET_COLLATERAL_LIST, _result.data);
  },

  async fetchLink({ commit }) {
    const _result = await getLink();
    commit(SET_LINK_LIST, _result.data);
  },

  async fetchCollateralClassificationList({ commit }, id: string) {
    const _result = await getCollateralClassificationList(id);
    commit(SET_COLLATERAL_CLASSIFICATION_LIST, _result.data);
  },

  async fetchScript({ commit }, id: string) {
    const _result = await getScript(id);
    commit(SET_SCRIPT, _result.data);
  },

  async addScript(
    { commit },
    marketScriptModel: MarketingScriptModel
  ): Promise<MarketingScriptModel> {
    const _result = await addScript(marketScriptModel);
    return _result.data;
  },

  async addLink({ commit }, content: AddLinkModel): Promise<ResponseModel> {
    const _result = await addLink(content);
    if (_result.status === 200) {
      const _linkResult = await getLink();
      commit(SET_LINK_LIST, _linkResult.data);
    }
    return _result.data;
  },
  async fetchLinkListing({ commit }) {
    const _response = await getLinkListing();
    commit(SET_LINKLISTING, _response.data);
  },
  async fetchLinkDetail({ commit }, code: string) {
    const _response = await getLinkDetail(code);
    commit(SET_LINKDETAIL, _response.data);
  },
  async fetchLinkDeleteAllowed({}, code: string): Promise<boolean> {
    const _response = await getLinkDeleteAllowed(code);
    return _response.data;
  },
  async fetchPlayerLinkCreated({ commit }) {
    const _response = await getPlayerLinkCreated();
    const _result = _response.data;
    commit(SET_INVALID_LINK_CODE, _result.InvalidCode);
    commit(SET_PRODUCT_LISTING, _result.LandingPages);
  },
  async playerLinkCreated(
    { commit },
    item: PlayerLinkCreated
  ): Promise<boolean> {
    const _response = await playerLinkCreated(item);
    if (_response.status === 200) {
      return true;
    } else return false;
  },
  async deleteLink({ commit }, code: string): Promise<boolean> {
    const _response = await deleteplayerLink(code);
    if (_response.status === 200) {
      return true;
    } else return false;
  },
};

export default actions;
