import { SelectOptionItem } from '@/store/typings';
import { GetterTree } from 'vuex';
import { CommonState } from './typings';
import { changeYearMonthToLangKey } from '@/utils/monthFormat';

const getters: GetterTree<CommonState, any> = {
  //ChatTool
  getChatToolOptions(state: CommonState): SelectOptionItem[] {
    const _match = state.ChatTools.filter((item) => item.IsEnabled === true);
    return _match.map(
      (item) =>
        <SelectOptionItem>{
          key: item.Code,
          text: item.LanguageKey,
        }
    );
  },
  getChatToolTextByCode: (state: CommonState) => (code: string): string => {
    const _item = state.ChatTools.find((item) => item.Code == code);
    if (_item === undefined) return '————';
    return _item?.LanguageKey;
  },

  //DialCode
  getDialCodeOptions(state: CommonState): SelectOptionItem[] {
    return state.DialCodes.map(
      (item) =>
        <SelectOptionItem>{
          key: item.Code,
          text: item.Descr,
        }
    );
  },
  getDialCodeTextByCode: (state: CommonState) => (code: string): string => {
    const _item = state.DialCodes.find((item) => item.Code == code);
    if (_item === undefined) return '————';
    return _item?.Descr;
  },
  getLastMonthAsMonth(state: CommonState): string[] {
    return state.Last3Months.map<string>((item) => item.substring(0, 7));
  },
  getDefaultMonthAsMonth(state: CommonState): string {
    const _index = 2;
    var _last = state.Last3Months[_index];
    return _last.substring(0, 7);
  },
};

export default getters;
