import { changeMonthToString } from '@/utils/monthFormat';
import { GetterTree } from 'vuex';
import {
  ReportState,
  GRPSummaryTabItem,
  GPRSummaryItem,
  GrossProductRevenueSummary,
  GPRProductFilterItem,
  GPRSummary,
  GPRProductSummary,
  GPRProductSummaryProductItem,
  GrossProductRevenueMemberDetailSummary,
  SubAffiliateItemKey,
  SubAffiliateItem,
  CollateralPerformanceItemKey,
} from './typings';

const getters: GetterTree<ReportState, any> = {
  getGPRProductFilterItems(state: ReportState): GPRProductFilterItem[] {
    const _summaryList = state.GPRProductSummary.Data.ProductSummaryList;
    if (_summaryList == null) return [];
    if (_summaryList.length === 0) return [];
    return state.GPRProductSummary.Data.ProductSummaryList[0].ProductList.map(
      (item) =>
        <GPRProductFilterItem>{
          DisplayName: item.DisplayName,
          ProductCode: item.ProductCode,
        }
    );
  },
  getGRPProductFilterSelectedItems(state: ReportState): GPRProductFilterItem[] {
    return state.GPRProductFilterSelectedItems;
  },
  getGRPSummaryTabs(state: ReportState): GRPSummaryTabItem[] {
    let _tabItems: GRPSummaryTabItem[] = [{ Key: 'summary', Text: 'summary' }];
    state.GPRSummary.Data.SummaryList.forEach(function(item) {
      _tabItems.push({
        Key: item.YearMonth.toString().substring(0, 7),
        Text: changeMonthToString(new Date(item.YearMonth).getMonth()),
      });
    });
    return _tabItems;
  },
  getGPRSummaryItems(state: ReportState): GPRSummaryItem[] {
    return state.GPRSummary.Data.SummaryList;
  },
  getGRPSummaryByYearMonth: (state) => (yearMonth: string): GPRSummaryItem => {
    if (yearMonth.length > 7) {
      yearMonth = yearMonth.substring(0, 7);
    }
    if (yearMonth === 'summary')
      return {
        GrossProductRevenueSummary: [],
        Currency: '',
        TotalGrossRevenue: 0,
        TotalNetRevenue: 0,
        YearMonth: new Date(),
      };

    let _summaryItem = state.GPRSummary.Data.SummaryList.find(
      (item) => item.YearMonth.toString().substring(0, 7) === yearMonth
    );
    if (_summaryItem === undefined)
      throw new Error('getGRPSummaryByYearMonth > _summaryItem');
    return _summaryItem;
  },
  getGPRProductSummary(state: ReportState): GPRProductSummary {
    return state.GPRProductSummary.Data;
  },
  getGPRProductSummaryItemByProduct: (state) => (
    settlementType: string,
    productCode: string
  ): GPRProductSummaryProductItem | undefined => {
    if (productCode === undefined) return undefined;

    let _searchProductCode = productCode.replaceAll('-', '');

    let _productSummary = state.GPRProductSummary.Data;
    let _productSummaryItem = _productSummary.ProductSummaryList.find(
      (item) => item.SettlementType === parseInt(settlementType)
    );

    if (_productSummaryItem === undefined) return undefined;

    let _productItem = _productSummaryItem.ProductList.find(
      (item) => item.ProductCode === _searchProductCode
    );

    if (_productItem === undefined) {
      let _liveCasino = 'Live Casino';
      let _childrenProductItem = _productSummaryItem.ProductList.find(
        (item) => item.ProductCode === _liveCasino
      );

      _productItem = _childrenProductItem?.ChildProductList.find(
        (item) => item.ProductCode === _searchProductCode
      );
    }

    if (_productItem === undefined) return undefined;

    return _productItem;
  },
  getGPRProductRevenueSummary: (state) => (
    yearMonth: string,
    settlementType: string
  ): GrossProductRevenueSummary => {
    if (yearMonth.length > 7) {
      yearMonth = yearMonth.substring(0, 7);
    }
    if (yearMonth === 'summary')
      return {
        SettlementType: 0,
        NetRevenue: 0,
        GrossRevenue: 0,
      };

    let _summaryItem = state.GPRSummary.Data.SummaryList.find(
      (item) => item.YearMonth.toString().substring(0, 7) === yearMonth
    );
    if (_summaryItem === undefined)
      throw new Error('getGPRProductRevenueSummary > _summaryItem');

    let _productRevenueSummary = _summaryItem.GrossProductRevenueSummary.find(
      (item) => item.SettlementType === parseInt(settlementType)
    );
    if (_productRevenueSummary === undefined)
      throw new Error('getGPRProductRevenueSummary > _productRevenueSummary');

    return _productRevenueSummary;
  },
  getGPRMemberDetailSummary(
    state: ReportState
  ): GrossProductRevenueMemberDetailSummary {
    throw new Error(
      'getGPRMemberDetailSummary > GrossProductRevenueMemberDetailSummary'
    );

    /*
    let _totalGrossRevenue : number = 0;
    let _
    state.GPRProductDetail
    */
  },
  getSubAffiliateDefaultMonth(state: ReportState): string {
    const _firstIndex = 0;
    const _lastIndex = state.SubAffiliates.length - 1;
    return state.SubAffiliates[_lastIndex].YearMonth;
  },
  getSubAffiliateMonths(state: ReportState): string[] {
    const _months = state.SubAffiliates.map(function(item) {
      return item.YearMonth;
    });
    return _months;
  },
  getSubAffiliateSearchItems: (state: ReportState, getters) => (
    yearMonth: string
  ): SubAffiliateItemKey[] => {
    if (yearMonth === undefined) {
      yearMonth = getters.getSubAffiliateDefaultMonth;
    }
    const _items = state.SubAffiliates.find((x) => x.YearMonth === yearMonth)
      ?.Items;
    if (_items === undefined) return [];
    const _array = _items.map(function(item) {
      return {
        AffCode: item.AffCode,
        SettlementType: item.SettlementType,
        Id: item.Id,
      };
    });
    return _array;
  },
  getSubAffiliateItemByIndex: (state: ReportState, getters) => (
    yearMonth: string,
    index: number
  ): SubAffiliateItem => {
    const _defaultItem = {
      Id: '--',
      SettlementType: 0,
      AffCode: '--',
      Currency: '--',
      TotalCommission: 0,
      NewRegistration: 0,
      NewDepositor: 0,
      ActiveMembers: 0,
    };
    if (yearMonth === undefined) {
      yearMonth = getters.getSubAffiliateDefaultMonth;
    }
    if (yearMonth.length > 7) {
      yearMonth = yearMonth.substring(0, 7);
    }
    var _monthItem = state.SubAffiliates.find(
      (item) => item.YearMonth.toString().substring(0, 7) === yearMonth
    );
    if (_monthItem === undefined) return _defaultItem;
    var _match = _monthItem.Items;
    return _match[index];
  },
  getSubAffiliateItemByMonth: (state: ReportState) => (
    yearMonth: string
  ): SubAffiliateItem[] => {
    var _monthItem = state.SubAffiliates.find(
      (item) => item.YearMonth.toString().substring(0, 7) === yearMonth
    );
    if (_monthItem === undefined) return [];
    return _monthItem.Items;
  },
  getSubAffiliateItem: (state: ReportState, getters) => (
    yearMonth: string,
    affCode: string,
    settlementType: number
  ): SubAffiliateItem => {
    const _defaultItem = {
      Id: '--',
      SettlementType: 0,
      AffCode: '--',
      Currency: '--',
      TotalCommission: 0,
      NewRegistration: 0,
      NewDepositor: 0,
      ActiveMembers: 0,
    };
    if (yearMonth === undefined) {
      yearMonth = getters.getSubAffiliateDefaultMonth;
    }
    if (yearMonth.length > 7) {
      yearMonth = yearMonth.substring(0, 7);
    }
    if (settlementType == null) settlementType = 0;
    var _monthItem = state.SubAffiliates.find(
      (item) => item.YearMonth.toString().substring(0, 7) === yearMonth
    );
    if (_monthItem === undefined) return _defaultItem;
    var _match = _monthItem?.Items.find(
      (item) =>
        item.AffCode.toLowerCase() === affCode.toLowerCase() &&
        item.SettlementType === settlementType
    );
    if (_match === undefined) return _defaultItem;
    return _match;
  },
  getCollateralPerformanceSearchItems: (state: ReportState): string[] => {
    const _items = state.CollateralPerformances;
    if (_items == null) return [];
    const _array = _items.map(function(item) {
      return {
        CreativeId: item.CreativeId,
        MediaSource: item.MediaSource,
        Size: item.BannerSize,
      };
    });
    const _searchItems: string[] = _array.map(function(item) {
      return JSON.stringify(item);
    });
    return _searchItems;
  },
  hasDomainCounting: (state: ReportState): boolean => {
    if (state.CollateralPerformanceDomainList.Link1.length > 2) return true;
    return false;
  },
};

export default getters;
