import { Module } from 'vuex';
import { KycState } from './typings';
import { RootState } from '@/store/typings';
import actions from './actions';
import mutations from './mutations';
const state: KycState = {
  JumioLaunchModel: {
    Id: '',
    errorUrl: '',
    language: '',
    needSelfie: 0,
    successUrl: '',
    type: '',
  },
  JumioLaunchUrlResultModel: {
    timestamp: '',
    transactionReference: '',
    redirectUrl: '',
    serviceAvailable: 0,
  },
  AddDocfileModel: {
    doctype: 0,
    attachments: [
      {
        FileId: 0,
        FileName: '',
        FileStatus: 0,
        Content: '',
      },
    ],
    remarks: '',
  },
  CheckProfileStatusModel: {
    isCheckWD: 0,
    isFirsttime: 0,
  },
  ProfileStatusModel: {
    photoIdStatus: '',
    photoIdDescr: '',
    occupationStatus: '',
    occupationDescr: '',
    threePartyStatus: '',
    threePartyDescr: '',
  },
  VerificationStatusModel: {
    verificationStatus: '',
  },
  OccupationModel: {
    isFullTime: 0,
    isPartTime: 0,
    occupationComment: '',
  },
  DeclarationModel: {
    fullName: '',
    relationshipToAffiliate: '',
    countryofResidence: '',
    dateofBirth: '',
    usingThirdPartyWD: 0,
  },
  FirstTimeWDModel: {
    isFullTime: 0,
    isPartTime: 0,
    occupationComment: '',
    fullName: '',
    relationshipToAffiliate: '',
    countryofResidence: '',
    dateofBirth: '',
    usingThirdPartyWD: 0,
  },
  PortalRemarksModel: {
    RemarksList: [
      {
        docSubmissionId: 0,
        code: '',
      },
    ],
  },
  WDStatusModel: {
    isfirstTimeWD: 0,
    isChangeWD: 0,
  },
  WDStatusRequestModel: {
    commissionMode: '',
  },
};
const Kyc: Module<KycState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};
export default Kyc;
