import { GetterTree } from "vuex";
import { AnnouncementState } from "./typings";

const getters: GetterTree<AnnouncementState, any> = {
  getMarqueeMessage(state: AnnouncementState): string {
    return state.AnnouncementMessage.Content;
  },
};

export default getters;
