export const supportLanguages = [
    'en-gb',
    'zh-cn',
    'vi-vn',
    'th-th',
    'ko-kr',
    'id-id',
    'pt-br',
    'km-kh',
    'ja-jp'
  ];
  
  export const defaultLanguage = supportLanguages[0];