import { render, staticRenderFns } from "./DialogQueue.vue?vue&type=template&id=90f8b9ca&scoped=true&"
import script from "./DialogQueue.vue?vue&type=script&lang=js&"
export * from "./DialogQueue.vue?vue&type=script&lang=js&"
import style0 from "./DialogQueue.vue?vue&type=style&index=0&id=90f8b9ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90f8b9ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VCard,VCardActions,VCardText,VDialog,VDivider,VOverlay})
