import axiosInstance from '@/utils/axios-instance';
import { AxiosResponse } from 'axios';
import { ResponseModel } from '../Web/typings';
import {
  AccountProfile,
  AccountWebSite,
  ChangePassWord,
  ResetAccountPassword,
  SubmitTransaction,
} from './typings';

export function getAffiliateProfile() {
  return axiosInstance.get('/Api/Account/Profile', {}, false);
}

export function updateProfile(AffiliateDetailModel: AccountProfile) {
  const data = {
    Id: AffiliateDetailModel.Id,
    Code: AffiliateDetailModel.Code,
    FirstName: AffiliateDetailModel.FirstName,
    FirstNameinfo: AffiliateDetailModel.FirstNameinfo,
    LastName: AffiliateDetailModel.LastName,
    LastNameinfo: AffiliateDetailModel.LastNameinfo,
    Language: AffiliateDetailModel.Language,
    Currency: AffiliateDetailModel.Currency,
    Email: AffiliateDetailModel.Email,
    Emailinfo: AffiliateDetailModel.Emailinfo,
    DialCode: AffiliateDetailModel.DialCode,
    ContactNo: AffiliateDetailModel.ContactNo,
    ContactNoinfo: AffiliateDetailModel.ContactNoinfo,
    ChatTool: AffiliateDetailModel.ChatTool,
    ChatAddress: AffiliateDetailModel.ChatAddress,
    ChatAddressinfo: AffiliateDetailModel.ChatAddressinfo,
    Address: AffiliateDetailModel.Address,
    Addressinfo: AffiliateDetailModel.Addressinfo,
    City: AffiliateDetailModel.City,
    Cityinfo: AffiliateDetailModel.Cityinfo,
    Country: AffiliateDetailModel.Country,
    Postal: AffiliateDetailModel.Postal,
    Postalinfo: AffiliateDetailModel.Postalinfo,
    OwnWebsite: AffiliateDetailModel.OwnWebsite,
    Describe: AffiliateDetailModel.Describe,
    WebsiteList: AffiliateDetailModel.WebsiteList,
    CommRcvOpt: AffiliateDetailModel.CommRcvOpt,
    CommRcvList: AffiliateDetailModel.CommRcvList,
    SecurityQuestion: AffiliateDetailModel.SecurityQuestion,
    SecurityAnswer: AffiliateDetailModel.SecurityAnswer,
    ProfileList: AffiliateDetailModel.ProfileList,
  };
  return axiosInstance.post('/Api/Account/Update', data, false);
}

export function getSecurityQuestions() {
  return axiosInstance.get('/Api/Web/SecurityQuestions', {}, false);
}

export function getAffiliateCommissionBalance() {
  return axiosInstance.get(
    '/Api/Account/GetAffiliateCommissionBalance',
    {},
    false
  );
}

export function getAccountWebSite() {
  return axiosInstance.get('/Api/Account/GetAccountWebSite', {}, false);
}

export function updateAccountWebSite(AccountWebSiteModel: AccountWebSite) {
  const data = {
    OwnWebsite: AccountWebSiteModel.OwnWebsite,
    Describe: AccountWebSiteModel.Describe,
    WebsiteList: AccountWebSiteModel.WebsiteList,
  };
  return axiosInstance.post('/Api/Account/UpdateAccountWebSite', data, false);
}

export function changePassword(ChangePassWordModel: ChangePassWord) {
  const data = {
    Current: ChangePassWordModel.Current,
    NewPass: ChangePassWordModel.NewPass,
  };
  return axiosInstance.post('/Api/Account/Password', data, false);
}

export function getisFirstLogin() {
  return axiosInstance.get('/Api/Account/GetisFirstLogin', {}, false);
}

export function getAffiliateCode(affiliateId) {
  const params = { affiliateId };
  const payload = { params };
  return axiosInstance.get('/Api/Account/GetAffiliateCode', payload, false);
}

export function getAffiliateCommRcvList() {
  return axiosInstance.get('/Api/Account/GetAffiliateCommRcvList', {}, false);
}

export function getSecurityQuestion(
  userNameOrEmail: string
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Account/GetSecurityQuestionByUserNameOrEmail';
  const _param = { UserNameOrEmail: userNameOrEmail };
  return axiosInstance.post(_url, _param, false);
}

export function sumbitResetAccountPassword(
  value: ResetAccountPassword
): Promise<AxiosResponse<ResponseModel>> {
  const _url = '/Api/Account/ResetPassword';
  const _param = value;
  return axiosInstance.post(_url, _param, false);
}

export function getTransaction() {
  const _url = '/Api/Account/Transaction';
  return axiosInstance.get(_url, {}, false);
}

export function submitTransaction(submit: SubmitTransaction) {
  const _url = '/Api/Account/Transaction';
  return axiosInstance.put(_url, submit, false);
}
