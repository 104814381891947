export const GET_SECURITY_QUESTIONS = 'GET_SECURITY_QUESTIONS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_STATIC_CONTENT = 'SET_STATIC_CONTENT';
export const SET_HTML_RESOURCE = 'SET_HTML_RESOURCE';
export const SET_SHOW_LOGIN_DIALOG = 'SET_SHOW_LOGIN_DIALOG';
export const SET_SHOW_SIGNUP_DIALOG = 'SET_SHOW_SIGNUP_DIALOG';
export const SET_SHOW_FORGET_ACCOUNT_PASSWORD_DIALOG =
  'SET_SHOW_FORGET_ACCOUNT_PASSWORD_DIALOG';
export const SET_HIDE_ON_SCROLL_DOWN_IN_MOBILE_ENABLE =
  'SET_HIDE_ON_SCROLL_DOWN_IN_MOBILE_ENABLE';
