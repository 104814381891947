import Auth from '@/store/modules/auth';
import Account from '@/store/modules/Account';
import Announcement from '@/store/modules/Announcement';
import DialogQueue from '@/store/modules/dialogQueue';
import Common from '@/store/modules/Common';
import Web from '@/store/modules/Web';
import Marketing from '@/store/modules/Marketing';
import Payment from '@/store/modules/Payment';
import Dashboard from '@/store/modules/Dashboard';
import Report from '@/store/modules/Report';
import Register from '@/store/modules/Register';
import Messages from '@/store/modules/Messages';
import Kyc from '@/store/modules/Kyc';

const modules = {
  Announcement,
  Auth,
  Common,
  DialogQueue,
  Account,
  Web,
  Marketing,
  Payment,
  Dashboard,
  Report,
  Register,
  Messages,
  Kyc,
};

export default modules;
