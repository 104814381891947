import { MutationTree } from "vuex";
import { AnnouncementState } from "./typings";
import { SET_ANNOUNCEMENT_LIST, SET_MARQUEE_LOGIN } from "./mutation-types";

const mutations: MutationTree<AnnouncementState> = {
  [SET_ANNOUNCEMENT_LIST]: (state: AnnouncementState, announcementList) => {
    state.Announcement = announcementList;
  },
  [SET_MARQUEE_LOGIN]: (state: AnnouncementState, marqueeBeforeLogin) => {
    state.AnnouncementMessage.Content = marqueeBeforeLogin.Content;
  },
};
export default mutations;
