import { ActionTree } from 'vuex';
import {
  InboxQuery,
  MessageState,
  ComposeMessageItem,
  SentMessage,
} from './typings';
import { RootState } from '@/store/typings';
import {
  composeMessage,
  deleteMessage,
  draft,
  getInboxes,
  getMessage,
  getUnReadCount,
  markAsRead,
  markAsUnRead,
  sentMessage,
} from './api';
import {
  SET_INBOXES,
  SET_MESSAGE,
  SET_UNREAD_COUNT,
  ADD_MESSAGE,
} from './mutation-types';
const actions: ActionTree<MessageState, RootState> = {
  async fetchInboxes({ commit }, query: InboxQuery): Promise<void> {
    const _response = await getInboxes(query.page, query.pageSize);
    const _data = _response.data;
    commit(SET_INBOXES, _data);
  },
  async fetchMessages({ commit }, id: string): Promise<void> {
    const _response = await getMessage(id);
    const _data = _response.data;
    commit(SET_MESSAGE, _data);
  },
  async addMessage({ commit }, message): Promise<void> {
    commit(ADD_MESSAGE, message);
    commit(ADD_MESSAGE, message);
  },
  async fetchUnReadCount({ commit }): Promise<void> {
    const _response = await getUnReadCount();
    const _data = _response.data;
    commit(SET_UNREAD_COUNT, _data);
  },
  async Draft({ commit }, item: ComposeMessageItem): Promise<string> {
    const _response = await draft(
      item.id,
      item.subject,
      item.content,
      item.attachments
    );
    if (_response.status === 200) {
      return _response.data;
    }
    return '';
  },
  async ComposeMessage({ commit }, item: ComposeMessageItem): Promise<boolean> {
    const _response = await composeMessage(
      item.id,
      item.subject,
      item.content,
      item.attachments
    );
    if (_response.status === 200) {
      return true;
    } else return false;
  },
  async SentMessage({ commit }, item: SentMessage): Promise<boolean> {
    const _response = await sentMessage(
      item.id,
      item.content,
      item.attachments
    );
    if (_response.status === 200) {
      return true;
    } else return false;
  },
  async DeleteMessage({ commit }, items: string[]): Promise<boolean> {
    const _response = await deleteMessage(items);
    if (_response.status === 200) {
      return true;
    } else return false;
  },
  async MarkAsRead({ commit }, items: string[]): Promise<boolean> {
    const _response = await markAsRead(items);
    if (_response.status === 200) {
      return true;
    } else return false;
  },
  async MarkAsUnRead({ commit }, items: string[]): Promise<boolean> {
    const _response = await markAsUnRead(items);
    if (_response.status === 200) {
      return true;
    } else return false;
  },
};

export default actions;
