import { Module } from 'vuex';
import { AccountState } from './typings';
import { RootState } from '@/store/typings';
import actions from './actions';
import mutations from './mutations';
const state: AccountState = {
  ChangePassWord: {
    Current: '',
    NewPass: '',
  },
  AccountWebSite: {
    OwnWebsite: '',
    Describe: 0,
    WebsiteList: [
      {
        SortNum: 0,
        Id: '',
        WebUrl: '',
        WebDescr: '',
      },
    ],
  },
  AccountBalance: {
    FirstName: '',
    LastName: '',
    Currency: '',
    Balance: 0,
    isFirstName: 0,
    isLastName: 0,
  },
  AccountProfile: {
    //General Account
    Id: '',
    Code: '',
    FirstName: '',
    FirstNameinfo: '',
    LastName: '',
    LastNameinfo: '',
    Language: '',
    Currency: '',
    //Contact
    Email: '',
    Emailinfo: '',
    DialCode: 0,
    ContactNo: '',
    ContactNoinfo: '',
    ChatTool: '',
    ChatAddress: '',
    ChatAddressinfo: '',
    //Location
    Address: '',
    Addressinfo: '',
    City: '',
    Cityinfo: '',
    Country: '',
    Postal: '', //PostalCodeZip
    Postalinfo: '',
    //Website
    OwnWebsite: 0,
    Describe: '',
    WebsiteList: [
      {
        Id: '',
        Url: '',
        Descr: '',
      },
    ],
    //Transaction
    CommRcvOpt: '',
    CommRcvList: [
      {
        FieldName: '',
        Description: '',
        Support: false,
        Data: [
          {
            FieldName: '',
            FieldValue: '',
            Description: '',
          },
        ],
      },
    ],
    //Security
    SecurityQuestion: '',
    SecurityAnswer: '',
    //Extras
    ProfileList: [
      {
        FieldName: '',
        FieldValue: '',
        Description: '',
      },
    ],
  },
  ChangePassWordResponse: {
    Status: -1,
    Message: '',
  },
  IsFirstLoginResponse: {
    isFirstLogin: true,
  },
  OwnAffiliateIdResponse: {
    ownAffiliateId: '',
  },
  CommRcvListResponse: {
    CommssnRcvList: [
      {
        Id: '',
        AccountId: 0,
        FieldName: '',
        CreatedTime: new Date(),
      },
    ],
  },
  JumioLaunchModel: {
    Id: '',
    errorUrl: '',
    language: '',
    needSelfie: 0,
    successUrl: '',
    type: '',
  },
  JumioLaunchUrlResultModel: {
    timestamp: '',
    transactionReference: '',
    redirectUrl: '',
    serviceAvailable: 0,
  },
  AddDocfileModel: {
    doctype: 0,
    remarks: '',
  },
  Transaction: {
    PublicField: '--',
    Items: [
      {
        FieldName: '--',
        Data: [
          {
            FieldName: '--',
            FieldValue: '--',
          },
        ],
      },
    ],
  },
};
const Account: Module<AccountState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};
export default Account;
