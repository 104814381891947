import { SelectOptionItem } from '@/store/typings';
import { GetterTree } from 'vuex';
import * as _marketing from './typings';

const getters: GetterTree<_marketing.MarketingState, any> = {
  //Link
  getLinkNameAsArray(state: _marketing.MarketingState): string[] {
    const _linksPlayers = state.LinkListing.Players;
    const _array: string[] = [];
    for (let index = 0; index < _linksPlayers.length; index++) {
      _array.push(_linksPlayers[index].Code);
    }
    const _linksDeprecations = state.LinkListing.Deprecations;
    for (let index = 0; index < _linksDeprecations.length; index++) {
      _array.push(_linksDeprecations[index].Code);
    }
    return _array;
  },
  getNewLinkItemByCode: (state: _marketing.MarketingState) => (
    code: string
  ): _marketing.PlayersItem | undefined => {
    const _match = state.LinkListing.Players.find((x) => x.Code == code);
    return _match;
  },
  getLinkItemByCode: (state: _marketing.MarketingState) => (
    code: string
  ): _marketing.DeprecationsItem | undefined => {
    const _match = state.LinkListing.Deprecations.find((x) => x.Code == code);
    return _match;
  },
  getDefaultLangId(state: _marketing.MarketingState): string[] {
    const _preferredItem = state.BannerFilter.find(
      (x) => x.IsPreferred === true
    );
    const _defaultItem = state.BannerFilter.find((x) => x.IsDefault === true);
    if (_preferredItem === undefined) return [];
    if (_defaultItem === undefined) return [];
    if (_preferredItem.Id === _defaultItem.Id) return [_preferredItem.Id];
    return [_defaultItem.Id, _preferredItem.Id];
  },
  getPreferredLangId(state: _marketing.MarketingState): string {
    const _preferredItem = state.BannerFilter.find(
      (x) => x.IsPreferred === true
    );
    if (_preferredItem === undefined) return '';
    return _preferredItem.Id;
  },
  getFirstSelectedLangId: (state: _marketing.MarketingState) => (
    classificationId: string[]
  ): string => {
    let _langId = '';
    state.BannerFilter.forEach((item) => {
      item.Classifications.forEach((classificationItem) => {
        classificationId.forEach((selectedClassificationId) => {
          if (
            classificationItem.Id.toLowerCase() ===
            selectedClassificationId.toLowerCase()
          ) {
            if (_langId === '') {
              _langId = item.Id;
            }
          }
        });
      });
    });
    return _langId;
  },
  checkLinkCodeInvalid: (state: _marketing.MarketingState) => (
    code: string
  ): boolean => {
    const _excludes = state.InvalidLinkCode.map((value) => {
      return value.toLowerCase();
    });
    if (_excludes.includes(code.toLowerCase()) === true) {
      return false;
    } else {
      return true;
    }
  },

  getBannerSelectedChip: (state: _marketing.MarketingState) => (
    classificationId: string[]
  ): _marketing.BannerSelectedChip | undefined => {
    var _match: _marketing.BannerSelectedItem[] = [
      {
        LangId: '--',
        ClassificationId: '--',
        LangText: '--',
        ClassificationText: '--',
      },
    ];
    var _chipItems: _marketing.BannerSelectedChipItem[] = [];
    if (classificationId != null) {
      _match = [];
      state.BannerFilter.forEach((item) => {
        item.Classifications.forEach((classificationItem) => {
          classificationId.forEach((selectedClassificationId) => {
            if (
              classificationItem.Id.toLowerCase() ===
              selectedClassificationId.toLowerCase()
            )
              _match.push({
                LangId: item.Id,
                ClassificationId: selectedClassificationId,
                LangText: item.Name,
                ClassificationText: classificationItem.Name,
              });
          });
        });
      });
      _match.forEach((item) => {
        const _match = _chipItems.find(
          (x) => x.Id === item.LangId && x.IsLanguage === true
        );
        if (_match != null) {
        } else {
          _chipItems.push({
            Id: item.LangId,
            Name: item.LangText,
            IsLanguage: true,
            IsCollapse: false,
            Count: 0,
          });
        }
        _chipItems.push({
          Id: item.ClassificationId,
          Name: item.ClassificationText,
          IsLanguage: false,
          IsCollapse: false,
          Count: 0,
        });
      });
      let _count = 0;
      let _index = 0;
      _chipItems.forEach((item) => {
        if (item.IsLanguage === false) {
          _count = _count + 1;
        }
        if (item.IsLanguage === true) {
          _chipItems[_index].Count = _count;
          _index = _chipItems.findIndex((x) => x.Id === item.Id);
          _count = 0;
        }
      });
      _chipItems[_index].Count = _count;

      //Summary selected language and classification count for close icon
      const _languageCount = _chipItems.reduce((count, item) => {
        if (item.IsLanguage === true) {
          return count + 1;
        }
        return count;
      }, 0);
      const _classificationCount = _chipItems.reduce((count, item) => {
        if (item.IsLanguage === false) {
          return count + 1;
        }
        return count;
      }, 0);

      //Collapse classification to ALL if select all items
      _index = 0;
      let _flag = false;
      let _collaspeItems: _marketing.BannerSelectedChipItem[] = [];
      _chipItems.forEach((chipItem) => {
        _index = _index + 1;
        if (chipItem.IsLanguage === true && chipItem.IsCollapse === false) {
          _collaspeItems.push(chipItem);

          const _items = state.BannerFilter.find(
            (x) => x.Id.toLowerCase() === chipItem.Id.toLowerCase()
          )?.Classifications;
          const _array = _items?.map(function(item) {
            return item.Id;
          });
          if (_array?.length === chipItem.Count) {
            _flag = true;
            _collaspeItems.push({
              Id: chipItem.Id,
              Name: 'All',
              IsLanguage: true,
              IsCollapse: true,
              Count: 0,
            });
          } else {
            _flag = false;
          }
        } else {
          if (
            chipItem.IsLanguage === false &&
            chipItem.IsCollapse === false &&
            _flag === true
          ) {
          } else {
            _collaspeItems.push({
              Id: chipItem.Id,
              Name: chipItem.Name,
              IsLanguage: chipItem.IsLanguage,
              IsCollapse: false,
              Count: 0,
            });
          }
        }
      });
      _chipItems = _collaspeItems;

      return {
        isClassificationClose: _classificationCount > 1,
        isLanguageClose: _languageCount > 1,
        items: _chipItems,
      };
    }
    return {
      isClassificationClose: false,
      isLanguageClose: false,
      items: [],
    };
  },
  getClassificationItems: (state: _marketing.MarketingState) => (
    langId: string
  ): _marketing.BannerClassificationItem[] => {
    let _match = state.BannerFilter.find(
      (x) => x.Id.toLowerCase() === langId?.toLowerCase()
    )?.Classifications;
    if (_match === undefined) return [];
    return _match;
  },
  getClassificationIdAsArray: (state: _marketing.MarketingState) => (
    langId: string
  ): string[] => {
    const _items = state.BannerFilter.find(
      (x) => x.Id.toLowerCase() === langId?.toLowerCase()
    )?.Classifications;
    if (_items === undefined) return [];
    const _array = _items.map(function(item) {
      return item.Id;
    });
    return _array;
  },
  getBannerNameAsArray(state: _marketing.MarketingState): string[] {
    const _bannerNames = state.BannerListing;
    const _array: string[] = [];
    for (let index = 0; index < _bannerNames.length; index++) {
      _array.push(_bannerNames[index].Name);
    }
    return _array;
  },
  getBannerItemByName: (state: _marketing.MarketingState) => (
    name: string
  ): _marketing.BannerListingItem | undefined => {
    const _match = state.BannerListing.find((x) => x.Name == name);
    return _match;
  },
  getLinkProducts(state: _marketing.MarketingState): SelectOptionItem[] {
    return state.Products.map(
      (item) =>
        <SelectOptionItem>{
          key: item.Code,
          text: item.Key,
        }
    );
  },
};

export default getters;
