import { Module } from 'vuex';
import { MessageState } from './typings';
import { RootState } from '@/store/typings';
import actions from './actions';
import mutations from './mutations';
//import getters from './getters';

const state: MessageState = {
  Inboxes: {
    Items: [
      {
        Id: '',
        Subject: '',
        Content: '',
        Date: '',
        IsRead: false,
        IsDraft: false,
      },
    ],
    Page: 1,
    PageSize: 1,
    Total: 0,
    Previous: 0,
    Next: 0,
    CurrentPageFirstIndex: 1,
    CurrentPageLastIndex: 1,
  },
  Message: {
    Id: '',
    Subject: '',
    Items: [
      {
        Id: '',
        Content: '',
        SentDate: '',
        FromAffiliate: false,
      },
    ],
    Attachments: [
      {
        Id: '',
        FileName: '',
        Content: '',
      },
    ],
  },
  Draft: {
    Id: '',
    Subject: '',
    Items: [
      {
        Id: '',
        Content: '',
        SentDate: '',
        FromAffiliate: false,
      },
    ],
    Attachments: [
      {
        Id: '',
        FileName: '',
        Content: '',
      },
    ],
  },
  UnReadCount: 0,
};

const Messages: Module<MessageState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  //getters,
};
export default Messages;
