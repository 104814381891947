import { MutationTree } from 'vuex';
import { AccountState } from './typings';
import {
  SET_ACCOUNT_PROFILE,
  SET_ACCOUNT_COMMISSION_BALANCE,
  SET_ACCOUNT_WEBSITE,
  SET_ACCOUNT_CHANGE_PASSWORD,
  SET_ACCOUNT_IS_FIRST_LOGIN,
  SET_ACCOUNT_AFFILIATE_ID,
  SET_COMMRCV_LIST,
  SET_JUMIOLAUNCH_MODEL,
  SET_ACCOUNT_TRANSACTION,
} from './mutation-types';

const mutations: MutationTree<AccountState> = {
  [SET_ACCOUNT_PROFILE]: (state: AccountState, profile) => {
    state.AccountProfile.Id = profile.Id;
    (state.AccountProfile.Code = profile.Code),
      (state.AccountProfile.FirstName = profile.FirstName);
    state.AccountProfile.FirstNameinfo = profile.FirstNameinfo;
    state.AccountProfile.LastName = profile.LastName;
    state.AccountProfile.LastNameinfo = profile.LastNameinfo;
    state.AccountProfile.Language = profile.Language;
    state.AccountProfile.Currency = profile.Currency;
    state.AccountProfile.Email = profile.Email;
    state.AccountProfile.Emailinfo = profile.Emailinfo;
    state.AccountProfile.DialCode = profile.DialCode;
    state.AccountProfile.ContactNo = profile.ContactNo;
    state.AccountProfile.ContactNoinfo = profile.ContactNoinfo;
    state.AccountProfile.ChatTool = profile.ChatTool;
    state.AccountProfile.ChatAddress = profile.ChatAddress;
    state.AccountProfile.ChatAddressinfo = profile.ChatAddressinfo;
    state.AccountProfile.Address = profile.Address;
    state.AccountProfile.Addressinfo = profile.Addressinfo;
    state.AccountProfile.City = profile.City;
    state.AccountProfile.Cityinfo = profile.Cityinfo;
    state.AccountProfile.Country = profile.Country;
    state.AccountProfile.Postal = profile.Postal;
    state.AccountProfile.Postalinfo = profile.Postalinfo;
    state.AccountProfile.OwnWebsite = profile.OwnWebsite;
    state.AccountProfile.Describe = profile.Describe;
    state.AccountProfile.WebsiteList = profile.WebsiteList;
    state.AccountProfile.CommRcvOpt = profile.CommRcvOpt;
    state.AccountProfile.CommRcvList = profile.CommRcvList;
    state.AccountProfile.SecurityQuestion = profile.SecurityQuestion;
    state.AccountProfile.SecurityAnswer = profile.SecurityAnswer;
    state.AccountProfile.ProfileList = profile.ProfileList;
  },
  [SET_ACCOUNT_COMMISSION_BALANCE]: (state: AccountState, accountBalance) => {
    state.AccountBalance.FirstName = accountBalance.FirstName;
    state.AccountBalance.LastName = accountBalance.LastName;
    state.AccountBalance.Currency = accountBalance.Currency;
    state.AccountBalance.Balance = accountBalance.Balance;
  },
  [SET_ACCOUNT_WEBSITE]: (state: AccountState, AccountWebSite) => {
    state.AccountWebSite.OwnWebsite = AccountWebSite.OwnWebsite;
    state.AccountWebSite.Describe = AccountWebSite.Describe;
    state.AccountWebSite.WebsiteList = AccountWebSite.WebsiteList;
  },
  [SET_ACCOUNT_CHANGE_PASSWORD]: (
    state: AccountState,
    ChangePassWordResponse
  ) => {
    state.ChangePassWordResponse.Status = ChangePassWordResponse.Status;
    state.ChangePassWordResponse.Message = ChangePassWordResponse.Message;
  },
  [SET_ACCOUNT_IS_FIRST_LOGIN]: (state: AccountState, IsFirstLoginResponse) => {
    state.IsFirstLoginResponse = IsFirstLoginResponse;
  },
  [SET_ACCOUNT_AFFILIATE_ID]: (state: AccountState, OwnAffiliateIdResponse) => {
    state.OwnAffiliateIdResponse = OwnAffiliateIdResponse;
  },
  [SET_COMMRCV_LIST]: (state: AccountState, CommRcvListResponse) => {
    state.CommRcvListResponse = CommRcvListResponse;
  },
  [SET_JUMIOLAUNCH_MODEL]: (state: AccountState, JumioLaunchUrlResultModel) => {
    console.log(JumioLaunchUrlResultModel);
    state.JumioLaunchUrlResultModel = JumioLaunchUrlResultModel;
  },
  [SET_ACCOUNT_TRANSACTION]: (state: AccountState, transaction) => {
    state.Transaction = transaction;
  },
};
export default mutations;
